import { styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';

export const SectionContainer = styled('div', {
  alignItems: 'center',
  backgroundColor: '$brandLightGray',
  borderRadius: '$10',
  display: 'flex',
  fontFamily: '$inter',
  justifyContent: 'space-between',
  marginBottom: '$16',
  minHeight: '$44',
  padding: '$12',
  width: '100%',
});

export const Title = styled('span', {
  color: '$uiBlackHighEmphasis',
  fontSize: '$10',
  fontWeight: 600,
  letterSpacing: '$1_2',
  lineHeight: pxToRem(16),
  textTransform: 'uppercase',
});

export const Optional = styled(Title, {
  color: '$uiBlackDisabled',
  letterSpacing: '$1_5',
});

export const IconContainer = styled('span', {
  '& > div > button > svg': {
    color: '$uiBlackMediumEmphasis',
  },
});
