import * as React from 'react';

export const ContraLightbulbIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#contra-lightbulb_svg__filter0_iii_39_15906)">
      <path
        clipRule="evenodd"
        d="M12 0a8 8 0 00-5.091 14.171c.726.6 1.314 1.389 1.447 2.321l.276 1.932A3 3 0 0011.602 21h.796a3 3 0 002.97-2.576l.276-1.932c.133-.932.72-1.72 1.447-2.32A8 8 0 0012 0z"
        fill="url(#contra-lightbulb_svg__paint0_radial_39_15906)"
        fillRule="evenodd"
      />
    </g>
    <g filter="url(#contra-lightbulb_svg__filter1_ii_39_15906)">
      <path
        d="M8.5 18.5h7l-.205 2.05a.5.5 0 01-.498.45H9.203a.5.5 0 01-.497-.45L8.5 18.5z"
        fill="url(#contra-lightbulb_svg__paint1_linear_39_15906)"
      />
    </g>
    <g filter="url(#contra-lightbulb_svg__filter2_ii_39_15906)">
      <path
        d="M10 23h4v.5l-.624.416a.5.5 0 01-.277.084H10.9a.5.5 0 01-.277-.084L10 23.5V23z"
        fill="url(#contra-lightbulb_svg__paint2_linear_39_15906)"
      />
    </g>
    <g filter="url(#contra-lightbulb_svg__filter3_ii_39_15906)">
      <path
        d="M9.25 22.5a.5.5 0 01.5-.5h4.5a.5.5 0 010 1h-4.5a.5.5 0 01-.5-.5z"
        fill="url(#contra-lightbulb_svg__paint3_linear_39_15906)"
      />
    </g>
    <g filter="url(#contra-lightbulb_svg__filter4_ii_39_15906)">
      <path
        d="M9.25 21.5a.5.5 0 01.5-.5h4.5a.5.5 0 010 1h-4.5a.5.5 0 01-.5-.5z"
        fill="url(#contra-lightbulb_svg__paint4_linear_39_15906)"
      />
    </g>
    <g
      filter="url(#contra-lightbulb_svg__filter5_f_39_15906)"
      opacity={0.7}
    >
      <path
        d="M14.5 2l.19.953a3 3 0 002.357 2.358L18 5.5v1l-.953.19a3 3 0 00-2.358 2.357L14.5 10h-1l-.19-.953a3 3 0 00-2.357-2.358L10 6.5v-1l.953-.19a3 3 0 002.358-2.357L13.5 2h1z"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={22}
        id="contra-lightbulb_svg__filter0_iii_39_15906"
        width={17}
        x={3.5}
        y={-0.5}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={1} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0.943902 0 0 0 0.3 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_39_15906"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_39_15906"
          result="effect2_innerShadow_39_15906"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.854152 0 0 0 0 0.17878 0 0 0 0 0.0395814 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_39_15906"
          result="effect3_innerShadow_39_15906"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={3.5}
        id="contra-lightbulb_svg__filter1_ii_39_15906"
        width={8}
        x={8}
        y={18}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.9665 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_39_15906"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.110208 0 0 0 0 0.268237 0 0 0 0 0.575 0 0 0 0.6 0" />
        <feBlend
          in2="effect1_innerShadow_39_15906"
          result="effect2_innerShadow_39_15906"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={2}
        id="contra-lightbulb_svg__filter2_ii_39_15906"
        width={5}
        x={9.5}
        y={22.5}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.944167 0 0 0 0 0.720833 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_39_15906"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.110208 0 0 0 0 0.268237 0 0 0 0 0.575 0 0 0 0.6 0" />
        <feBlend
          in2="effect1_innerShadow_39_15906"
          result="effect2_innerShadow_39_15906"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={2}
        id="contra-lightbulb_svg__filter3_ii_39_15906"
        width={6.5}
        x={8.75}
        y={21.5}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.9665 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_39_15906"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.110208 0 0 0 0 0.268237 0 0 0 0 0.575 0 0 0 0.6 0" />
        <feBlend
          in2="effect1_innerShadow_39_15906"
          result="effect2_innerShadow_39_15906"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={2}
        id="contra-lightbulb_svg__filter4_ii_39_15906"
        width={6.5}
        x={8.75}
        y={20.5}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.9665 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_39_15906"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.110208 0 0 0 0 0.268237 0 0 0 0 0.575 0 0 0 0.6 0" />
        <feBlend
          in2="effect1_innerShadow_39_15906"
          result="effect2_innerShadow_39_15906"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={10.5}
        id="contra-lightbulb_svg__filter5_f_39_15906"
        width={10.5}
        x={8.75}
        y={0.75}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_39_15906"
          stdDeviation={0.625}
        />
      </filter>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="contra-lightbulb_svg__paint1_linear_39_15906"
        x1={9}
        x2={14.5}
        y1={19}
        y2={19}
      >
        <stop
          offset={0.12}
          stopColor="#919191"
        />
        <stop
          offset={0.458}
          stopColor="#8ABDC8"
        />
        <stop
          offset={0.764}
          stopColor="#E8F2FF"
        />
        <stop
          offset={1}
          stopColor="#848484"
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="contra-lightbulb_svg__paint2_linear_39_15906"
        x1={10.5}
        x2={13.25}
        y1={22}
        y2={22}
      >
        <stop offset={0.12} />
        <stop
          offset={0.458}
          stopColor="#444274"
        />
        <stop
          offset={0.764}
          stopColor="#3948F8"
        />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="contra-lightbulb_svg__paint3_linear_39_15906"
        x1={9.643}
        x2={13.964}
        y1={22.2}
        y2={22.2}
      >
        <stop
          offset={0.12}
          stopColor="#919191"
        />
        <stop
          offset={0.458}
          stopColor="#8ABDC8"
        />
        <stop
          offset={0.764}
          stopColor="#E8F2FF"
        />
        <stop
          offset={1}
          stopColor="#848484"
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="contra-lightbulb_svg__paint4_linear_39_15906"
        x1={9.643}
        x2={13.964}
        y1={21.2}
        y2={21.2}
      >
        <stop
          offset={0.12}
          stopColor="#919191"
        />
        <stop
          offset={0.458}
          stopColor="#8ABDC8"
        />
        <stop
          offset={0.764}
          stopColor="#E8F2FF"
        />
        <stop
          offset={1}
          stopColor="#848484"
        />
      </linearGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(-3.49999 10 -10 -3.49999 14 6.5)"
        gradientUnits="userSpaceOnUse"
        id="contra-lightbulb_svg__paint0_radial_39_15906"
        r={1}
      >
        <stop
          offset={0.271}
          stopColor="#FFE795"
        />
        <stop
          offset={0.651}
          stopColor="#FFC632"
        />
        <stop
          offset={1}
          stopColor="#FF791C"
        />
      </radialGradient>
    </defs>
  </svg>
);
