import { styled } from '@/stitches/index.js';

const SelectMenuList = styled('ul', {
  display: 'block',
  listStyle: 'none',
  margin: '0',
  overscrollBehavior: 'contain',
  padding: '0',
  position: 'relative',
});

SelectMenuList.displayName = 'SelectMenu.List';

export { SelectMenuList };
