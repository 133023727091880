import { type ConfirmShortCodeInput } from '@/__generated__/api.js';
import {
  type useConfirmShortCodeMutation,
  type useConfirmShortCodeMutation$data,
} from '@/__generated__/relay/useConfirmShortCodeMutation.graphql.js';
import { useToast } from '@/components/Toasts/hooks/useToast.js';
import { captureException } from '@sentry/nextjs';
import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';

type ConfirmShortCodeFunction = ({
  input,
  onCompleted,
  onError,
}: {
  input: ConfirmShortCodeInput;
  onCompleted: (response: useConfirmShortCodeMutation$data) => void;
  onError?: () => void;
}) => void;

export const useConfirmShortCode = (): [
  confirmShortCode: ConfirmShortCodeFunction,
  confirmShortCodeLoading: boolean,
] => {
  const toast = useToast();

  const [confirmShortCode, confirmShortCodeLoading] =
    useMutation<useConfirmShortCodeMutation>(graphql`
      mutation useConfirmShortCodeMutation($input: ConfirmShortCodeInput!) {
        confirmShortCode(input: $input) {
          errors {
            __typename
            message
          }

          result
          visitor {
            userAccount {
              ...CurrentUserContext_userAccount
              ...InquiryContextProvider_userAccount
            }
          }
        }
      }
    `);

  const onConfirmShortCode = useCallback<ConfirmShortCodeFunction>(
    ({ input, onCompleted, onError }) => {
      confirmShortCode({
        onCompleted: (response) => {
          const errors = response.confirmShortCode.errors;

          if (errors?.length) {
            toast.error(
              `Something went wrong: ${
                errors[0]?.message ?? 'Unknown error'
              }. Please try again.`,
            );
          } else {
            onCompleted(response);
          }
        },
        onError: (error) => {
          captureException(error);
          toast.error(
            'Oops! Something went wrong. Our team has been notified.',
            { role: 'alert' },
          );
          onError?.();
        },
        variables: {
          input,
        },
      });
    },
    [confirmShortCode, toast],
  );

  return [onConfirmShortCode, confirmShortCodeLoading];
};
