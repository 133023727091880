import { styled } from '@/stitches/index.js';

const SelectMenuDialog = styled('div', {
  '& > *': {
    '&:first-child': {
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
    },

    '&:last-child': {
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
  },
  backgroundColor: '$white',
  borderRadius: '$10',
  boxShadow: '$basic24',
  overflow: 'auto',
  textAlign: 'left',
});

SelectMenuDialog.displayName = 'SelectMenu.Dialog';

export { SelectMenuDialog };
