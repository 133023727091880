import * as React from 'react';

export const ContraConfettiIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#contra-confetti_svg__a)">
      <path
        d="m1.597 20.014 2.03-10.152c.228-1.137 1.107-2.046 2.262-1.94 1.54.14 3.825.792 6.111 3.078 2.424 2.424 3.012 5.214 3.099 7.038.057 1.194-.856 2.124-2.026 2.375l-9.096 1.949a2 2 0 0 1-2.38-2.348Z"
        fill="url(#contra-confetti_svg__b)"
      />
    </g>
    <g filter="url(#contra-confetti_svg__c)">
      <path
        d="M12.203 11.5c2.626 2.96 3.312 6.702 2.1 7.777-1.211 1.075-4.322-.453-6.949-3.413-2.626-2.96-3.773-6.23-2.562-7.306 1.212-1.075 4.784-.017 7.41 2.943Z"
        fill="url(#contra-confetti_svg__d)"
      />
    </g>
    <path
      d="M11.755 11.904c2.304 2.596 3.027 5.768 2.065 6.622-.962.853-3.609-.559-5.912-3.154-2.303-2.596-3.39-5.392-2.428-6.245.962-.854 3.972.182 6.275 2.777Z"
      fill="url(#contra-confetti_svg__e)"
    />
    <mask
      height={19}
      id="contra-confetti_svg__f"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={19}
      x={5}
      y={0}
    >
      <path
        d="M7.909 15.372c2.303 2.595 4.95 4.007 5.911 3.154H24V0H5.48v9.127c-.962.854.125 3.65 2.429 6.245Z"
        fill="#3A82F7"
      />
    </mask>
    <g mask="url(#contra-confetti_svg__f)">
      <mask
        height={11}
        id="contra-confetti_svg__h"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
        width={10}
        x={5}
        y={8}
      >
        <path
          d="M11.755 11.904c2.304 2.596 3.027 5.768 2.065 6.622-.962.853-3.609-.559-5.912-3.154-2.303-2.596-3.39-5.392-2.428-6.245.962-.854 3.972.182 6.275 2.777Z"
          fill="url(#contra-confetti_svg__g)"
        />
      </mask>
      <g
        filter="url(#contra-confetti_svg__i)"
        mask="url(#contra-confetti_svg__h)"
      >
        <path
          d="M7.183 18.584s2.687-2.5 3-6c.313-3.5-1.251-5.554-1.251-5.554"
          stroke="url(#contra-confetti_svg__j)"
          strokeLinecap="round"
          strokeWidth={1.5}
        />
      </g>
      <mask
        height={11}
        id="contra-confetti_svg__l"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
        width={10}
        x={5}
        y={8}
      >
        <path
          d="M11.755 11.904c2.304 2.596 3.027 5.768 2.065 6.622-.962.853-3.609-.559-5.912-3.154-2.303-2.596-3.39-5.392-2.428-6.245.962-.854 3.972.182 6.275 2.777Z"
          fill="url(#contra-confetti_svg__k)"
        />
      </mask>
      <g
        filter="url(#contra-confetti_svg__m)"
        mask="url(#contra-confetti_svg__l)"
      >
        <path
          d="M20.28 15.366s-2.079-.96-5.258.127C11.842 16.58 10.3 18.67 10.3 18.67"
          stroke="url(#contra-confetti_svg__n)"
          strokeLinecap="round"
          strokeWidth={1.5}
        />
      </g>
      <g filter="url(#contra-confetti_svg__o)">
        <path
          d="M5.5 16.5s2.686-2.5 3-6C8.813 7 7.247 4.946 7.247 4.946"
          stroke="url(#contra-confetti_svg__p)"
          strokeLinecap="round"
          strokeWidth={1.5}
        />
      </g>
      <g filter="url(#contra-confetti_svg__q)">
        <path
          d="M20 14s-2.5-1.5-6-.5S9 17 9 17"
          stroke="url(#contra-confetti_svg__r)"
          strokeLinecap="round"
          strokeWidth={1.5}
        />
      </g>
    </g>
    <path
      clipRule="evenodd"
      d="m2.282 16.586-.434 2.174a12.833 12.833 0 0 0 3.407 3.328l2.214-.474c-1.263-.572-2.494-1.46-3.576-2.68a11.18 11.18 0 0 1-1.61-2.348Zm6.824 4.677 2.744-.588a7.003 7.003 0 0 1-.64-.165c-1.649-.506-3.514-1.698-5.128-3.517-1.533-1.727-2.477-3.611-2.831-5.25l-.572 2.86a14.959 14.959 0 0 0 2.28 3.385c1.272 1.433 2.712 2.543 4.147 3.275Z"
      fill="url(#contra-confetti_svg__s)"
      fillRule="evenodd"
    />
    <g filter="url(#contra-confetti_svg__t)">
      <path
        d="m21.098 8.5-.217-.125a2.75 2.75 0 0 0-3.756 1.007L17 9.598"
        stroke="url(#contra-confetti_svg__u)"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </g>
    <g filter="url(#contra-confetti_svg__v)">
      <path
        d="m11.622 6.425.2-.116A3 3 0 0 0 12.92 2.21L12.8 2"
        stroke="url(#contra-confetti_svg__w)"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </g>
    <g filter="url(#contra-confetti_svg__x)">
      <circle
        cx={18.5}
        cy={3.5}
        fill="url(#contra-confetti_svg__y)"
        r={1.5}
      />
    </g>
    <g filter="url(#contra-confetti_svg__z)">
      <circle
        cx={14}
        cy={9}
        fill="url(#contra-confetti_svg__A)"
        r={1}
      />
    </g>
    <g filter="url(#contra-confetti_svg__B)">
      <circle
        cx={18}
        cy={17}
        fill="url(#contra-confetti_svg__C)"
        r={1}
      />
    </g>
    <g filter="url(#contra-confetti_svg__D)">
      <circle
        cx={2.5}
        cy={5.5}
        fill="url(#contra-confetti_svg__E)"
        r={1.5}
      />
    </g>
    <g filter="url(#contra-confetti_svg__F)">
      <circle
        cx={7.5}
        cy={1.5}
        fill="url(#contra-confetti_svg__G)"
        r={1.5}
      />
    </g>
    <g filter="url(#contra-confetti_svg__H)">
      <circle
        cx={21}
        cy={19}
        fill="url(#contra-confetti_svg__I)"
        r={1}
      />
    </g>
    <defs>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(4.49999 -16.49997 13.9091 3.79339 .5 24)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__b"
        r={1}
      >
        <stop
          offset={0.004}
          stopColor="#FF824D"
        />
        <stop
          offset={0.053}
          stopColor="#FFC560"
        />
        <stop
          offset={0.102}
          stopColor="#FEBC64"
        />
        <stop
          offset={0.134}
          stopColor="#F89576"
        />
        <stop
          offset={0.177}
          stopColor="#FF778F"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(2.56896 9.49205 -7.7399 2.09475 9.43 9.508)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__e"
        r={1}
      >
        <stop
          offset={0.401}
          stopColor="#FF9D3C"
        />
        <stop
          offset={1}
          stopColor="#F56000"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(2.56896 9.49205 -7.7399 2.09475 9.43 9.508)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__g"
        r={1}
      >
        <stop
          offset={0.401}
          stopColor="#FF9D3C"
        />
        <stop
          offset={0.719}
          stopColor="#F56000"
        />
        <stop
          offset={1}
          stopColor="#D00C00"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(2.56896 9.49205 -7.7399 2.09475 9.43 9.508)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__k"
        r={1}
      >
        <stop
          offset={0.401}
          stopColor="#FF9D3C"
        />
        <stop
          offset={0.719}
          stopColor="#F56000"
        />
        <stop
          offset={1}
          stopColor="#D00C00"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(-1.99997 5.00002 -5.12815 -2.05122 8.5 8.5)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__p"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#E4EAFF"
        />
        <stop
          offset={0.547}
          stopColor="#AB9DF0"
        />
        <stop
          offset={1}
          stopColor="#5917AA"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(6.5 1 -.66466 4.32027 14.5 13)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__r"
        r={1}
      >
        <stop
          offset={0.205}
          stopColor="#FF24C8"
        />
        <stop
          offset={0.775}
          stopColor="#9F1093"
        />
        <stop
          offset={1}
          stopColor="#5F0A87"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(3 -14.5 13.41322 2.77516 1.5 22.5)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__s"
        r={1}
      >
        <stop
          offset={0.041}
          stopColor="#FF9D3C"
        />
        <stop
          offset={0.091}
          stopColor="#FF6635"
        />
        <stop
          offset={0.115}
          stopColor="#DA2E0A"
        />
        <stop
          offset={0.136}
          stopColor="#F743A5"
        />
        <stop
          offset={0.158}
          stopColor="#FF48CC"
        />
        <stop
          offset={0.172}
          stopColor="#A900A2"
        />
        <stop
          offset={0.981}
          stopColor="#E43700"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(1.81314 -.93267 .9446 1.83635 18.512 9.152)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__u"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#E4EAFF"
        />
        <stop
          offset={0.547}
          stopColor="#AA98F0"
        />
        <stop
          offset={1}
          stopColor="#A16CEA"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(-.07287 2.1821 -2.17864 -.07276 12.35 3.685)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__w"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#FFEBEB"
        />
        <stop
          offset={0.547}
          stopColor="#FFC560"
        />
        <stop
          offset={1}
          stopColor="#FF791C"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="rotate(122.779 8.6 6.73) scale(2.03896 2.06506)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__y"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#FF00C1"
        />
        <stop
          offset={0.547}
          stopColor="#AA1193"
        />
        <stop
          offset={1}
          stopColor="#5F0A87"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="rotate(122.779 4.748 8.274) scale(1.35931 1.37671)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__A"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#E4EAFF"
        />
        <stop
          offset={0.547}
          stopColor="#AA98F0"
        />
        <stop
          offset={1}
          stopColor="#A16CEA"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="rotate(122.005 4.474 13.435) scale(1.34771)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__C"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#FFEBEB"
        />
        <stop
          offset={0.547}
          stopColor="#FFC560"
        />
        <stop
          offset={1}
          stopColor="#FF791C"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="rotate(122.779 .054 3.366) scale(2.03896 2.06506)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__E"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#E4EAFF"
        />
        <stop
          offset={0.547}
          stopColor="#AA98F0"
        />
        <stop
          offset={1}
          stopColor="#A16CEA"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="rotate(122.005 3.612 2.749) scale(2.02157)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__G"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#FFEBEB"
        />
        <stop
          offset={0.547}
          stopColor="#FFC560"
        />
        <stop
          offset={1}
          stopColor="#FF791C"
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="rotate(122.779 5.52 15.183) scale(1.35931 1.37671)"
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__I"
        r={1}
      >
        <stop
          offset={0.051}
          stopColor="#FF00C1"
        />
        <stop
          offset={0.547}
          stopColor="#AA1193"
        />
        <stop
          offset={1}
          stopColor="#5F0A87"
        />
      </radialGradient>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={15.495}
        id="contra-confetti_svg__a"
        width={14.544}
        x={1.058}
        y={7.413}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={1} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0.943902 0 0 0 0.3 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_39_15915"
          result="effect2_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.854152 0 0 0 0 0.17878 0 0 0 0 0.0395814 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_39_15915"
          result="effect3_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={12.473}
        id="contra-confetti_svg__c"
        width={11.572}
        x={3.81}
        y={7.648}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_39_15915"
          stdDeviation={0.25}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={14.554}
        id="contra-confetti_svg__i"
        width={6.042}
        x={5.683}
        y={5.53}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_39_15915"
          stdDeviation={0.375}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={6.694}
        id="contra-confetti_svg__m"
        width={12.979}
        x={8.801}
        y={13.475}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_39_15915"
          stdDeviation={0.375}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={13.554}
        id="contra-confetti_svg__o"
        width={5.042}
        x={4.749}
        y={3.696}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.635294 0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 1 0" />
        <feBlend
          in2="shape"
          mode="multiply"
          result="effect1_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.619608 0 0 0 0 0.360784 0 0 0 0 0.909804 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_39_15915"
          mode="multiply"
          result="effect2_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={5.344}
        id="contra-confetti_svg__q"
        width={12.5}
        x={8.25}
        y={12.406}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.477754 0 0 0 0 0 0 0 0 0 0.6875 0 0 0 1 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={3.592}
        id="contra-confetti_svg__t"
        width={6.099}
        x={16.249}
        y={6.756}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.635294 0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 1 0" />
        <feBlend
          in2="shape"
          mode="multiply"
          result="effect1_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.619608 0 0 0 0 0.360784 0 0 0 0 0.909804 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_39_15915"
          mode="multiply"
          result="effect2_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={6.925}
        id="contra-confetti_svg__v"
        width={4.202}
        x={10.371}
        y={0.75}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={1} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0.943902 0 0 0 0.3 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_39_15915"
          result="effect2_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.854152 0 0 0 0 0.17878 0 0 0 0 0.0395814 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_39_15915"
          result="effect3_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={3}
        id="contra-confetti_svg__x"
        width={3}
        x={17}
        y={2}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.477754 0 0 0 0 0 0 0 0 0 0.6875 0 0 0 1 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={2.5}
        id="contra-confetti_svg__z"
        width={2.5}
        x={13}
        y={7.5}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.635294 0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 1 0" />
        <feBlend
          in2="shape"
          mode="multiply"
          result="effect1_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.619608 0 0 0 0 0.360784 0 0 0 0 0.909804 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_39_15915"
          mode="multiply"
          result="effect2_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={3}
        id="contra-confetti_svg__B"
        width={3}
        x={16.5}
        y={15.5}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={1} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0.943902 0 0 0 0.3 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_39_15915"
          result="effect2_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.854152 0 0 0 0 0.17878 0 0 0 0 0.0395814 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_39_15915"
          result="effect3_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={3.5}
        id="contra-confetti_svg__D"
        width={3.5}
        x={1}
        y={3.5}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.635294 0 0 0 0 0.392157 0 0 0 0 0.913725 0 0 0 1 0" />
        <feBlend
          in2="shape"
          mode="multiply"
          result="effect1_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.619608 0 0 0 0 0.360784 0 0 0 0 0.909804 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_39_15915"
          mode="multiply"
          result="effect2_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={4}
        id="contra-confetti_svg__F"
        width={4}
        x={5.5}
        y={-0.5}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.5}
          dy={0.5}
        />
        <feGaussianBlur stdDeviation={1} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0.943902 0 0 0 0.3 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.5}
          dy={-0.5}
        />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_39_15915"
          result="effect2_innerShadow_39_15915"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.854152 0 0 0 0 0.17878 0 0 0 0 0.0395814 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_39_15915"
          result="effect3_innerShadow_39_15915"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={2}
        id="contra-confetti_svg__H"
        width={2}
        x={20}
        y={18}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.375} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.477754 0 0 0 0 0 0 0 0 0 0.6875 0 0 0 1 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_39_15915"
        />
      </filter>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__d"
        x1={5.5}
        x2={14}
        y1={9}
        y2={19}
      >
        <stop stopColor="#FFC86F" />
        <stop
          offset={0.505}
          stopColor="#FFF4EE"
        />
        <stop
          offset={1}
          stopColor="#FFC2CA"
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__j"
        x1={5.997}
        x2={10.181}
        y1={8.441}
        y2={17.143}
      >
        <stop
          offset={0.411}
          stopColor="#D61901"
        />
        <stop
          offset={1}
          stopColor="#D61901"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="contra-confetti_svg__n"
        x1={13.914}
        x2={10.999}
        y1={15.5}
        y2={18.5}
      >
        <stop
          offset={0.411}
          stopColor="#D61901"
        />
        <stop
          offset={1}
          stopColor="#D61901"
          stopOpacity={0}
        />
      </linearGradient>
    </defs>
  </svg>
);
