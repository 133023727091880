import { animated } from '@/components/animated/animated.js';
import { type colors } from '@/stitches/colorPrimitives.js';
import { styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import {
  cloneElement,
  type ReactElement,
  type UIEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

const VerticalFadesContainer = styled('div', {
  height: 'fit-content',
  position: 'relative',
  width: '100%',
  zIndex: '2',
});

const Fade = styled(animated.div, {
  pointerEvents: 'none',
  position: 'absolute',
  width: '100%',
  zIndex: '1',
});

const TopFade = styled(Fade, {
  top: 0,
});

const BottomFade = styled(Fade, {
  bottom: 0,
});

type AddVerticalFadesProps = {
  readonly children: ReactElement;
  readonly color?: keyof typeof colors;
  readonly disabled?: boolean;
  readonly fadeHeight?: number;
};

export const AddVerticalFades = ({
  children,
  color = 'white',
  disabled = false,
  fadeHeight = 32,
}: AddVerticalFadesProps) => {
  const ref = useRef<HTMLElement>(null);
  const [shouldFade, setShouldFade] = useState(false);
  const [fadeTop, setFadeTop] = useState(false);
  const [fadeBottom, setFadeBottom] = useState(false);
  const [elementHeight, setElementHeight] = useState<number>(0);

  const onScroll = (uiEvent: UIEvent<HTMLElement, globalThis.UIEvent>) => {
    const resolvedTarget = uiEvent.currentTarget ?? uiEvent.target;
    if (!resolvedTarget) return;
    const { clientHeight, scrollHeight, scrollTop } = resolvedTarget;

    if (scrollHeight - scrollTop <= clientHeight) {
      setFadeBottom(false);
    } else if (scrollTop === 0) {
      setFadeTop(false);
    } else if (!fadeTop || !fadeBottom) {
      setFadeTop(true);
      setFadeBottom(true);
    }
  };

  useEffect(() => {
    if (ResizeObserver) {
      const ro = new ResizeObserver((entries) => {
        const [element] = entries;
        if (element) {
          const contentRect = element.contentRect;
          setElementHeight(contentRect.height);
        }
      });

      if (ref.current) {
        // observe ref for height changes
        ro.observe(ref.current);
      }

      return () => {
        ro.disconnect();
      };
    } else {
      if (ref.current) {
        setElementHeight(ref.current.clientHeight);
      }

      return () => {};
    }
  }, [ref]);

  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      const isElementOverflowed = element.clientHeight < element.scrollHeight;

      if (isElementOverflowed) {
        setShouldFade(true);
        setFadeBottom(true);
      } else {
        setShouldFade(false);
        setFadeBottom(false);
      }
    }
  }, [elementHeight, ref]);

  // Checks to see if this component AND it's consumer think we should be faded
  const shouldDisplayFades = !disabled && shouldFade;

  return (
    <VerticalFadesContainer>
      <TopFade
        animate={{ opacity: shouldDisplayFades && fadeTop ? 1 : 0 }}
        css={{
          background: `linear-gradient(to bottom, $${color},rgba(255, 255, 255, 0) 100%)`,
          height: pxToRem(fadeHeight),
        }}
        initial={false}
      />
      <BottomFade
        animate={{ opacity: shouldDisplayFades && fadeBottom ? 1 : 0 }}
        css={{
          background: `linear-gradient(to top, $${color},rgba(255, 255, 255, 0) 100%)`,
          height: pxToRem(fadeHeight),
        }}
        initial={false}
      />
      {cloneElement(children, { onScroll, ref })}
    </VerticalFadesContainer>
  );
};
