import { styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';

const TermsOfAgreementText = styled('p', {
  color: '$uiBlackMediumEmphasis',
  fontFamily: '$inter',
  fontSize: '$12',
  letterSpacing: '$0_15',
  lineHeight: 1.4,
  margin: '0 auto',
  maxWidth: pxToRem(360),
  textAlign: 'center',
  width: '100%',
});

const TermsOfAgreementLink = styled('a', {
  borderBottomColor: '$uiBlackMediumEmphasis',
  borderBottomStyle: 'solid',
  borderBottomWidth: '$1',
});

export const ContraTermsOfAgreement = () => {
  return (
    <TermsOfAgreementText>
      By continuing, you agree to Contra&apos;s{' '}
      <TermsOfAgreementLink href="https://contra.com/terms">
        Terms of Use
      </TermsOfAgreementLink>{' '}
      and confirm that you have read Contra&apos;s{' '}
      <TermsOfAgreementLink href="https://contra.com/policies/privacy">
        Privacy Policy
      </TermsOfAgreementLink>
    </TermsOfAgreementText>
  );
};
