/**
 * @generated SignedSource<<ba40a2fdec37348048136aea416d4304>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InquiryContextProvider_userAccount$data = {
  readonly createdJobPostings: {
    readonly count: number;
  };
  readonly paidProjects: {
    readonly count: number;
  };
  readonly " $fragmentType": "InquiryContextProvider_userAccount";
};
export type InquiryContextProvider_userAccount$key = {
  readonly " $data"?: InquiryContextProvider_userAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"InquiryContextProvider_userAccount">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InquiryContextProvider_userAccount",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "source": "GENERAL_INQUIRY"
          }
        },
        (v0/*: any*/)
      ],
      "concreteType": "UserAccountPaidProjectConnection",
      "kind": "LinkedField",
      "name": "paidProjects",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "paidProjects(filter:{\"source\":\"GENERAL_INQUIRY\"},first:1)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "status": [
              "APPROVED",
              "ARCHIVED",
              "PENDING",
              "PENDING_REVISION",
              "EXPIRED"
            ]
          }
        },
        (v0/*: any*/)
      ],
      "concreteType": "UserAccountCreatedJobPostingsConnection",
      "kind": "LinkedField",
      "name": "createdJobPostings",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "createdJobPostings(filter:{\"status\":[\"APPROVED\",\"ARCHIVED\",\"PENDING\",\"PENDING_REVISION\",\"EXPIRED\"]},first:1)"
    }
  ],
  "type": "UserAccount",
  "abstractKey": null
};
})();

(node as any).hash = "839e4f1d22cff6f910f85af7105adb6d";

export default node;
