import { getRouteName } from '@/utilities/getRouteName.js';
import { useRouter } from 'next/router';

export const useIPUtmParameters = (campaign: string) => {
  const router = useRouter();
  return {
    utm_campaign: campaign,
    utm_medium: 'independent_portfolios',
    utm_source: getRouteName(router.pathname),
  };
};
