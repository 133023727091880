import {
  ALL_RANGE_INPUT_LABELS,
  FIXED_RATE_RANGE_INPUT_KEYS,
  HOURLY_RANGE_INPUT_KEYS,
} from './constants.js';
import {
  type BudgetRangeKey,
  type BudgetRangeSelectOptions,
  type FixedRateRange,
  type RateBasedRange,
} from './types.js';
import { type JobPostingBudgetType } from '@/__generated__/api.js';
import { Money } from '@contra/money';

export const getBudgetRangeValuesFromInputType = (
  inputType?: FixedRateRange | RateBasedRange,
) => {
  switch (inputType) {
    case 'FIFTY_TO_SEVENTY_FIVE_PER_HOUR':
      return {
        feeMax: 75,
        feeMin: 50,
      };

    case 'FIVE_THOUSAND_TO_TEN_THOUSAND':
      return {
        feeMax: 10_000,
        feeMin: 5_000,
      };

    case 'MORE_THAN_FIFTY_THOUSAND': {
      return {
        feeMax: undefined,
        feeMin: 50_000,
      };
    }

    case 'MORE_THAN_TWO_HUNDRED_PER_HOUR': {
      return {
        feeMax: undefined,
        feeMin: 200,
      };
    }

    case 'ONE_HUNDRED_FIFTY_TO_TWO_HUNDRED_PER_HOUR': {
      return {
        feeMax: 200,
        feeMin: 150,
      };
    }

    case 'ONE_HUNDRED_TO_ONE_HUNDRED_FIFTY_PER_HOUR': {
      return {
        feeMax: 150,
        feeMin: 100,
      };
    }

    case 'ONE_THOUSAND_TO_FIVE_THOUSAND': {
      return {
        feeMax: 5_000,
        feeMin: 1_000,
      };
    }

    case 'SEVENTY_FIVE_TO_ONE_HUNDRED_PER_HOUR': {
      return {
        feeMax: 100,
        feeMin: 75,
      };
    }

    case 'TEN_THOUSAND_TO_TWENTY_THOUSAND': {
      return {
        feeMax: 20_000,
        feeMin: 10_000,
      };
    }

    case 'TWENTY_FIVE_TO_FIFTY_PER_HOUR': {
      return {
        feeMax: 50,
        feeMin: 25,
      };
    }

    case 'TWENTY_THOUSAND_TO_FIFTY_THOUSAND': {
      return {
        feeMax: 50_000,
        feeMin: 20_000,
      };
    }

    case 'TWO_HUNDRED_FIFTY_TO_ONE_THOUSAND': {
      return {
        feeMax: 1_000,
        feeMin: 250,
      };
    }

    default:
      throw new Error(`Got unexpected budget range input type ${inputType}`);
  }
};

const isStringFixedRateKey = (value: string): value is FixedRateRange => {
  return FIXED_RATE_RANGE_INPUT_KEYS.includes(value as FixedRateRange);
};

const isStringHourlyRateKey = (value: string): value is RateBasedRange => {
  return HOURLY_RANGE_INPUT_KEYS.includes(value as RateBasedRange);
};

export const getBudgetRangeValuesFromString = (key: string) => {
  try {
    if (isStringFixedRateKey(key)) {
      const { feeMax, feeMin } = getBudgetRangeValuesFromInputType(key);
      return {
        feeMax:
          typeof feeMax === 'number'
            ? Money.fromUsdDollars(feeMax).serialize()
            : undefined,
        feeMin:
          typeof feeMin === 'number'
            ? Money.fromUsdDollars(feeMin).serialize()
            : undefined,
        type: 'FIXED_PRICE' as const,
      };
    }

    if (isStringHourlyRateKey(key)) {
      const { feeMax, feeMin } = getBudgetRangeValuesFromInputType(key);
      return {
        feeMax:
          typeof feeMax === 'number'
            ? Money.fromUsdDollars(feeMax).serialize()
            : undefined,
        feeMin:
          typeof feeMin === 'number'
            ? Money.fromUsdDollars(feeMin).serialize()
            : undefined,
        interval: 'HOUR' as const,
        type: 'RATE' as const,
      };
    }

    return null;
  } catch {
    return null;
  }
};

const getInputTypeFromRateBasedBudgetRange = (budgetRange?: {
  feeMax?: number;
  feeMin?: number;
}): RateBasedRange | undefined => {
  if (!budgetRange?.feeMin || !budgetRange?.feeMax) return undefined;

  if (budgetRange.feeMin === 25 && budgetRange.feeMax === 50)
    return 'TWENTY_FIVE_TO_FIFTY_PER_HOUR';

  if (budgetRange.feeMin === 50 && budgetRange.feeMax === 75)
    return 'FIFTY_TO_SEVENTY_FIVE_PER_HOUR';

  if (budgetRange.feeMin === 75 && budgetRange.feeMax === 100)
    return 'SEVENTY_FIVE_TO_ONE_HUNDRED_PER_HOUR';

  if (budgetRange.feeMin === 100 && budgetRange.feeMax === 150)
    return 'ONE_HUNDRED_TO_ONE_HUNDRED_FIFTY_PER_HOUR';

  if (budgetRange.feeMin === 150 && budgetRange.feeMax === 200)
    return 'ONE_HUNDRED_FIFTY_TO_TWO_HUNDRED_PER_HOUR';

  if (
    budgetRange.feeMin === 200 &&
    budgetRange.feeMax === Number.POSITIVE_INFINITY
  )
    return 'MORE_THAN_TWO_HUNDRED_PER_HOUR';

  return undefined;
};

const getInputTypeFromFixedRateBudgetRange = (budgetRange?: {
  feeMax?: number;
  feeMin?: number;
}): FixedRateRange | undefined => {
  if (!budgetRange?.feeMin || !budgetRange?.feeMax) return undefined;

  if (budgetRange.feeMin === 250 && budgetRange.feeMax === 1_000)
    return 'TWO_HUNDRED_FIFTY_TO_ONE_THOUSAND';

  if (budgetRange.feeMin === 1_000 && budgetRange.feeMax === 5_000)
    return 'ONE_THOUSAND_TO_FIVE_THOUSAND';

  if (budgetRange.feeMin === 5_000 && budgetRange.feeMax === 10_000)
    return 'FIVE_THOUSAND_TO_TEN_THOUSAND';

  if (budgetRange.feeMin === 10_000 && budgetRange.feeMax === 20_000)
    return 'TEN_THOUSAND_TO_TWENTY_THOUSAND';

  if (budgetRange.feeMin === 20_000 && budgetRange.feeMax === 50_000)
    return 'TWENTY_THOUSAND_TO_FIFTY_THOUSAND';

  if (
    budgetRange.feeMin === 50_000 &&
    budgetRange.feeMax === Number.POSITIVE_INFINITY
  )
    return 'MORE_THAN_FIFTY_THOUSAND';

  return undefined;
};

const isBudgetRangeKey = (value: string): value is BudgetRangeKey => {
  return Boolean(
    [...HOURLY_RANGE_INPUT_KEYS, ...FIXED_RATE_RANGE_INPUT_KEYS].includes(
      value as BudgetRangeKey,
    ),
  );
};

export const getBudgetRangeKeyByValue = (
  value: string,
): BudgetRangeKey | undefined => {
  const budgetRangeKey = Object.keys(ALL_RANGE_INPUT_LABELS).find(
    (key) => ALL_RANGE_INPUT_LABELS[key as BudgetRangeKey] === value,
  );

  return budgetRangeKey && isBudgetRangeKey(budgetRangeKey)
    ? budgetRangeKey
    : undefined;
};

export const isBudgetRangeValue = (value: string) => {
  return Boolean(getBudgetRangeKeyByValue(value));
};

export const getInputTypeFromBudget = (budget?: {
  feeMax?: number;
  feeMin?: number;
  type?: JobPostingBudgetType;
}): BudgetRangeSelectOptions | undefined => {
  if (budget?.type === 'FIXED_PRICE') {
    const budgetType = getInputTypeFromFixedRateBudgetRange(budget);
    if (budgetType) {
      return { id: budgetType, title: ALL_RANGE_INPUT_LABELS[budgetType] };
    }
  }

  if (budget?.type === 'RATE') {
    const budgetType = getInputTypeFromRateBasedBudgetRange(budget);
    if (budgetType) {
      return { id: budgetType, title: ALL_RANGE_INPUT_LABELS[budgetType] };
    }
  }

  return undefined;
};
