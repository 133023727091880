/**
 * @generated SignedSource<<4355cdc06531084887151604cb8a18b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JobPostingBudgetType = "FIXED_PRICE" | "RATE" | "%future added value";
export type JobPostingStartTimeframe = "IMMEDIATELY" | "LATER" | "SOON" | "%future added value";
export type JobPostingSelectQuery$variables = {
  skip: boolean;
};
export type JobPostingSelectQuery$data = {
  readonly visitor?: {
    readonly userAccount: {
      readonly createdJobPostings: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly budget: {
              readonly feeMax: number | null;
              readonly feeMin: number;
              readonly type: JobPostingBudgetType;
            } | null;
            readonly id: string;
            readonly maxDuration: number | null;
            readonly minDuration: number | null;
            readonly organization: {
              readonly id: string;
              readonly logoImage: {
                readonly id: string;
                readonly uid: string;
                readonly url: string;
              } | null;
              readonly name: string;
            } | null;
            readonly startTimeframe: JobPostingStartTimeframe | null;
            readonly title: string;
          };
        }>;
      };
      readonly id: string;
    } | null;
  };
};
export type JobPostingSelectQuery = {
  response: JobPostingSelectQuery$data;
  variables: JobPostingSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserAccount",
  "kind": "LinkedField",
  "name": "userAccount",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "status": [
              "APPROVED",
              "ARCHIVED",
              "PENDING",
              "PENDING_REVISION",
              "EXPIRED"
            ]
          }
        }
      ],
      "concreteType": "UserAccountCreatedJobPostingsConnection",
      "kind": "LinkedField",
      "name": "createdJobPostings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserAccountCreatedJobPostingsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "JobPosting",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTimeframe",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minDuration",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxDuration",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "JobPostingBudget",
                  "kind": "LinkedField",
                  "name": "budget",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "feeMax",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "feeMin",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContraImage",
                      "kind": "LinkedField",
                      "name": "logoImage",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "uid",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "createdJobPostings(filter:{\"status\":[\"APPROVED\",\"ARCHIVED\",\"PENDING\",\"PENDING_REVISION\",\"EXPIRED\"]})"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobPostingSelectQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Visitor",
            "kind": "LinkedField",
            "name": "visitor",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobPostingSelectQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Visitor",
            "kind": "LinkedField",
            "name": "visitor",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "2de549d10650f5a6dc08f70fc5b558bb",
    "id": null,
    "metadata": {},
    "name": "JobPostingSelectQuery",
    "operationKind": "query",
    "text": "query JobPostingSelectQuery(\n  $skip: Boolean!\n) {\n  visitor @skip(if: $skip) {\n    userAccount {\n      id\n      createdJobPostings(filter: {status: [APPROVED, ARCHIVED, PENDING, PENDING_REVISION, EXPIRED]}) {\n        edges {\n          node {\n            id\n            title\n            startTimeframe\n            minDuration\n            maxDuration\n            budget {\n              feeMax\n              feeMin\n              type\n            }\n            organization {\n              id\n              name\n              logoImage {\n                uid\n                id\n                url\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "21730db7e0b99e033124756184c8515c";

export default node;
