import { useAnalytics } from './useAnalytics.js';
import {
  type FormNames,
  type FormSectionNames,
} from '@/services/analytics/propertyLibrary.js';
import { useCallback, useRef } from 'react';

type OnChangeForm<T extends FormSectionNames> = {
  [P in T]: Array<boolean | number | string | null | undefined>;
};

export const useTrackFormProgress = <T extends FormSectionNames>({
  formName,
}: {
  formName: FormNames;
}) => {
  const { track } = useAnalytics();
  const ref = useRef<T[]>([]);

  const trackFormProgress = useCallback(
    (formBySection: OnChangeForm<T>) => {
      for (const section of Object.keys(formBySection)) {
        const typedSection = section as T;
        const fieldsToBoolean = formBySection[typedSection].map((field) => {
          if (typeof field === 'string') {
            return field && field !== '';
          } else {
            return Boolean(field);
          }
        });
        const isFormSectionComplete = fieldsToBoolean.every(Boolean);

        if (isFormSectionComplete && !ref.current.includes(typedSection)) {
          ref.current = [...ref.current, typedSection];
          track('form', {
            form_name: formName,
            form_section: typedSection,
          });
        }
      }
    },
    [formName, track],
  );

  return trackFormProgress;
};
