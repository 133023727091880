import { useSearchParameter } from './useSearchParameter.js';
import { storage } from '@/utilities/storage.js';
import { useMount } from '@contra/react-hooks/useMount';
import { useState } from 'react';

const sessionStorage = storage('session');
const INTERNAL_TRAFFIC_KEY = 'CONTRA_IS_INTERNAL_TRAFFIC';

export const useIsInternalTraffic = () => {
  const isInternalTrafficParameter = useSearchParameter(
    'isInternalTraffic',
    'boolean',
  );
  const [isInternalTraffic, setIsInternalTraffic] = useState(
    isInternalTrafficParameter,
  );

  useMount(() => {
    const isInternalTrafficSessionStorage = sessionStorage.getItem
      ? sessionStorage.getItem(INTERNAL_TRAFFIC_KEY)
      : undefined;

    if (
      isInternalTrafficSessionStorage ||
      isInternalTrafficParameter ||
      document.referrer.includes('https://contra.com/')
    ) {
      setIsInternalTraffic(true);
      sessionStorage.setItem(INTERNAL_TRAFFIC_KEY, 'true');
    }
  });

  return isInternalTraffic;
};
