import { type FixedRateRange, type RateBasedRange } from './types.js';

export const FIXED_RATE_RANGE_INPUT_KEYS: FixedRateRange[] = [
  'TWO_HUNDRED_FIFTY_TO_ONE_THOUSAND',
  'ONE_THOUSAND_TO_FIVE_THOUSAND',
  'FIVE_THOUSAND_TO_TEN_THOUSAND',
  'TEN_THOUSAND_TO_TWENTY_THOUSAND',
  'TWENTY_THOUSAND_TO_FIFTY_THOUSAND',
  'MORE_THAN_FIFTY_THOUSAND',
];

export const HOURLY_RANGE_INPUT_KEYS: RateBasedRange[] = [
  'TWENTY_FIVE_TO_FIFTY_PER_HOUR',
  'FIFTY_TO_SEVENTY_FIVE_PER_HOUR',
  'SEVENTY_FIVE_TO_ONE_HUNDRED_PER_HOUR',
  'ONE_HUNDRED_TO_ONE_HUNDRED_FIFTY_PER_HOUR',
  'ONE_HUNDRED_FIFTY_TO_TWO_HUNDRED_PER_HOUR',
  'MORE_THAN_TWO_HUNDRED_PER_HOUR',
];

export const FIXED_RATE_RANGE_INPUT_LABELS: Record<FixedRateRange, string> =
  Object.freeze({
    FIVE_THOUSAND_TO_TEN_THOUSAND: '$5,000 - $10,000',
    MORE_THAN_FIFTY_THOUSAND: '$50,000+',
    ONE_THOUSAND_TO_FIVE_THOUSAND: '$1,000 - $5,000',
    TEN_THOUSAND_TO_TWENTY_THOUSAND: '$10,000 - $20,000',
    TWENTY_THOUSAND_TO_FIFTY_THOUSAND: '$20,000 - $50,000',
    TWO_HUNDRED_FIFTY_TO_ONE_THOUSAND: '$250 - $1,000',
  });

export const RATE_BASED_RANGE_INPUT_LABELS: Record<RateBasedRange, string> =
  Object.freeze({
    FIFTY_TO_SEVENTY_FIVE_PER_HOUR: '$50 - $75/hr',
    MORE_THAN_TWO_HUNDRED_PER_HOUR: '$200+/hr',
    ONE_HUNDRED_FIFTY_TO_TWO_HUNDRED_PER_HOUR: '$150 - $200/hr',
    ONE_HUNDRED_TO_ONE_HUNDRED_FIFTY_PER_HOUR: '$100 - $150/hr',
    SEVENTY_FIVE_TO_ONE_HUNDRED_PER_HOUR: '$75 - $100/hr',
    TWENTY_FIVE_TO_FIFTY_PER_HOUR: '$25 - $50/hr',
  });

export const ALL_RANGE_INPUT_LABELS = {
  ...FIXED_RATE_RANGE_INPUT_LABELS,
  ...RATE_BASED_RANGE_INPUT_LABELS,
};
