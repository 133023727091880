import { type ModalControls } from './types.js';
import { useAnalytics } from '@/hooks/useAnalytics.js';
import { useDeterministicToggle } from '@/hooks/useDeterministicToggle.js';
import {
  type ModalNames,
  type OtherButtonNames,
  type PropertyLibrary,
} from '@/services/analytics/propertyLibrary.js';
import { useMount } from '@contra/react-hooks/useMount';
import { useCallback, useRef } from 'react';

export const useModal = ({
  buttonName,
  isOpenByDefault,
  modalName,
}: {
  buttonName?: OtherButtonNames;
  isOpenByDefault?: boolean;
  modalName: ModalNames;
}): ModalControls => {
  const { track } = useAnalytics();
  // We need a separate ref to keep track when we open a modal automatically
  //  for the first time that the user is also able to open manually
  const hasOpenedOnce = useRef(false);
  // We intentionally don't pass isOpenByDefault
  const { close, isOpen, open } = useDeterministicToggle();

  const onClose = useCallback(
    (cta: PropertyLibrary['cta'] | null) => {
      if (cta !== null) {
        track('modal', {
          action: 'tapped',
          cta,
          modal_name: modalName,
        });
      }

      close();
    },
    [close, modalName, track],
  );

  const onOpen = useCallback(() => {
    // Should only run button event when modal is triggered manually by user
    // Yes, it would be nice to not have to duplicate events here,
    // but why confuse them PMs intentionally? No bueno.
    if (
      (buttonName && !isOpenByDefault) ||
      (buttonName && isOpenByDefault && hasOpenedOnce.current)
    ) {
      track('button', {
        action: 'tapped',
        button_name: buttonName,
      });
    }

    track('modal', {
      action: 'viewed',
      modal_name: modalName,
    });

    open();
    hasOpenedOnce.current = true;
  }, [buttonName, isOpenByDefault, modalName, open, track]);

  const onOpenChange = useCallback(
    // Runs before open value changes
    (nextOpenValue: boolean) => {
      if (nextOpenValue) {
        // Runs whenever open() is triggered
        onOpen();
      } else {
        // Only runs when:
        // 1. Clicking away from dialog
        // 2. Clicking on Modal.Close
        // Does not run when clicking secondary/cancel
        // button on modals (ie running close() thru a non radix comp)
        onClose('close');
      }
    },
    [onClose, onOpen],
  );

  useMount(() => {
    if (isOpenByDefault) {
      onOpen();
    }
  });

  return {
    isOpen,
    onClose,
    onOpen,
    // Only add this to a modal the user should be able to
    // close by clicking away or clicking the X
    onOpenChange,
  };
};
