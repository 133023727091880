import { ContraConfettiIcon } from '@/components/Icons/ContraConfettiIcon.js';
import { ContraRocketIcon } from '@/components/Icons/ContraRocketIcon.js';
import { Modal } from '@/components/Modal/Modal.js';
import { PlatformButton } from '@/components/PlatformButton/PlatformButton.js';
import { type PropertyLibrary } from '@/services/analytics/propertyLibrary.js';
import { getContraUrl } from '@/utilities/getContraUrl.js';

const ICON_SIZE = 54;

export const InquirySuccessModal = ({
  isFirstInquiry,
  isOpen,
  onClose,
}: {
  readonly isFirstInquiry: boolean;
  readonly isOpen: boolean;
  readonly onClose: (cta: PropertyLibrary['cta'] | null) => void;
}) => {
  return (
    <Modal.Root open={isOpen}>
      <Modal.Content
        css={{
          '@bp3': { paddingTop: '$48  ' },
        }}
      >
        <Modal.Body>
          {isFirstInquiry ? (
            <ContraConfettiIcon
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ) : (
            <ContraRocketIcon
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          )}
          <Modal.Title>
            {isFirstInquiry ? 'You sent your first inquiry!' : 'Inquiry sent!'}
          </Modal.Title>
          <Modal.Description>
            {isFirstInquiry
              ? 'We’ll notify you if the Independent accepts your inquiry request. All inquiries can be seen in the Messages tab. '
              : 'We’ll notify you if the Independent accepts your inquiry request.'}
          </Modal.Description>
          <Modal.Buttons>
            {isFirstInquiry ? (
              <PlatformButton
                as="a"
                href={getContraUrl('/messages')}
                onClick={() => onClose('primary')}
                variant="primary"
              >
                View Messages
              </PlatformButton>
            ) : (
              <PlatformButton
                onClick={() => onClose('primary')}
                variant="primary"
              >
                Okay
              </PlatformButton>
            )}
          </Modal.Buttons>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
