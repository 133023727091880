import { type InputElements } from './types.js';

export const DEFAULT_PLACEHOLDER_CHARACTER = '•';
export const INPUT_ID_PREFIX = 'codeInput-id-';
export const BLANK_PLACEHOLDER = '-';
export const CODE_REGEX = /[^\da-z]/giu;

// Create a dummy array of a specific length - useful for mapping elements
export const arrayOfLength = (length: number) =>
  Array.from({ length }, (_, index) => index);

// Replace a character in a string at a specific index
export const replaceAt = (
  stringToReplace: string,
  index: number,
  replacement: string,
) => {
  const prefix = stringToReplace.slice(0, index);
  const append = stringToReplace.slice(index + replacement.length);

  return `${prefix}${replacement}${append}`;
};

// Build helpers for navigating the inputs
export const getInputCursors = (
  references: InputElements,
  inputId: string,
  codeLength: number,
) => {
  const inputIndexString = inputId.replace(INPUT_ID_PREFIX, '');
  const inputIndex = Number.parseInt(inputIndexString, 10);
  const isLastInput = inputIndex === codeLength - 1;
  const nextInput = isLastInput ? 0 : inputIndex + 1;
  const previousInput = inputIndex === 0 ? 0 : inputIndex - 1;
  const currentInputElement = references[`${INPUT_ID_PREFIX}${inputIndex}`];
  const nextInputElement = references[`${INPUT_ID_PREFIX}${nextInput}`];
  const previousInputElement = references[`${INPUT_ID_PREFIX}${previousInput}`];
  const firstInputElement = references[`${INPUT_ID_PREFIX}0`];

  return {
    currentInputElement,
    firstInputElement,
    isLastInput,
    nextInput,
    nextInputElement,
    previousInput,
    previousInputElement,
  };
};

// Build the output that strips out our blank placeholder characters
export const buildOutputCode = (currentCode: string) => {
  return currentCode.replaceAll(BLANK_PLACEHOLDER, '').toUpperCase();
};

// Build the value to display in each input
export const buildInputValue = (currentValue: string) => {
  return !currentValue || currentValue === BLANK_PLACEHOLDER
    ? ''
    : currentValue;
};

// Filter a value by a regex
export const filterValueByRegex = (
  valueToFilter: string,
  filterRegex: RegExp,
) => {
  return (
    valueToFilter.replace(filterRegex, BLANK_PLACEHOLDER) || BLANK_PLACEHOLDER
  );
};
