import { Layout } from '@/components/Layout/Layout.js';
import { PlatformButton } from '@/components/PlatformButton/PlatformButton.js';
import { styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';

export const Form = styled('form', {
  margin: '$48 0 $24',
  width: '100%',
});

export const FormV2 = styled('form', {
  width: '100%',
});

export const StyledButton = styled(PlatformButton, {
  '&& path': {
    stroke: '$gray50',
  },
  '@bp0': {
    width: '100%',
  },
  '@bp2': {
    width: 'auto',
  },
  minWidth: pxToRem(164),
});

export const StyledTextLink = styled('a', {
  '&:hover,&:focus': {
    textDecoration: 'underline',
  },
  color: '$uiBlackMediumEmphasis',
  fontSize: '$12',
});

export const Row = styled(Layout.FlexColumn, {
  '& > *': {
    width: '100%',
  },
  '@bp2': {
    flexDirection: 'row',
  },
  alignItems: 'center',
  gap: '$16',
  justifyContent: 'space-between',
  width: '100%',
});

export const ButtonContainer = styled('div', {
  '@bp0': {
    flexDirection: 'column',
  },
  '@bp1': {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  display: 'flex',
  gap: '$8',
  paddingTop: '$24',
  width: '100%',
});

export const BackButton = styled('button', {
  '@bp0': {
    '& > span': {
      display: 'block',
    },
    '& > svg': {
      display: 'none',
    },
    borderRadius: '$25',
    width: '100%',
  },
  '@bp1': {
    '& > span': {
      display: 'none',
    },
    '& > svg': {
      display: 'block',
    },
    borderRadius: '50%',
    width: '$48',
  },
  alignItems: 'center',
  borderColor: '$gray40',
  borderStyle: 'solid',
  borderWidth: '$1',
  color: '$gray80',
  display: 'flex',
  fontFamily: '$inter',
  fontSize: '$16',
  fontStyle: 'normal',
  fontWeight: '600',
  height: '$48',
  justifyContent: 'center',
});

export const Header = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$16',
  height: pxToRem(69),
  marginBottom: '$24',
  width: '100%',
});

export const AvatarContainer = styled('div', {
  height: '$56',
});

export const HeaderText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$2',
});

export const HeaderTitle = styled('div', {
  color: '$black',
  fontFamily: '$inter',
  fontSize: '$23',
  fontStyle: 'normal',
  fontWeight: '600',
  letterSpcing: pxToRem(-0.25),
  lineHeight: '$32',
  textAlign: 'left',
});

export const HeaderSubtitle = styled('div', {
  color: '$gray70',
  fontFamily: '$inter',
  fontSize: '$14',
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: pxToRem(0.15),
  lineHeight: '$20',
  textAlign: 'left',
});
