import { ProductizedServiceType } from '@/__generated__/api.js';
import { type InquiryContextMutation } from '@/__generated__/relay/InquiryContextMutation.graphql.js';
import { type InquiryContextProvider_userAccount$key } from '@/__generated__/relay/InquiryContextProvider_userAccount.graphql.js';
import { type InquiryContextProviderQuery } from '@/__generated__/relay/InquiryContextProviderQuery.graphql.js';
import { Service_productizedService$data } from '@/__generated__/relay/Service_productizedService.graphql.js';
import { CompleteSignUpModal } from '@/components/CompleteSignUpModal/CompleteSignUpModal.js';
import { getBudgetRangeValuesFromString } from '@/components/InquiryModal/components/BudgetRangeSelect/utilities.js';
import { PROJECT_DURATION_OPTIONS } from '@/components/InquiryModal/components/ProjectDurationSelect/ProjectDurationSelect.js';
import {
  inquiryFormValidationSchema,
  inquiryFormValidationSchemaV2,
} from '@/components/InquiryModal/inquiryFormValidationSchema.js';
import { InquiryModal } from '@/components/InquiryModal/InquiryModal.js';
import { InquiryModalV2 } from '@/components/InquiryModal/InquiryModalV2.js';
import { InquirySuccessModal } from '@/components/InquiryModal/InquirySuccessModal.js';
import { InquirySuccessModalV2 } from '@/components/InquiryModal/InquirySuccessModalV2.js';
import {
  type InquiryFormData,
  InquiryFormV2Data,
} from '@/components/InquiryModal/types.js';
import { useModal } from '@/components/Modal/useModal.js';
import { ShortCodeConfirmationModal } from '@/components/ShortCodeConfirmationModal/ShortCodeConfirmationModal.js';
import { ShortCodeConfirmationModalV2 } from '@/components/ShortCodeConfirmationModal/ShortCodeConfirmationModalV2.js';
import { useToast } from '@/components/Toasts/hooks/useToast.js';
import { useAnalytics } from '@/hooks/useAnalytics.js';
import { useCurrentUser } from '@/hooks/useCurrentUser.js';
import { useFeatureFlag } from '@/hooks/useFeatureFlag.js';
import { useIsInternalTraffic } from '@/hooks/useIsInternalTraffic.js';
import { useSearchParameter } from '@/hooks/useSearchParameter.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import { type InquiryEventSection } from '@/services/analytics/propertyLibrary.js';
import { getContraUrl } from '@/utilities/getContraUrl.js';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { createContext, type ReactNode, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  graphql,
  // eslint-disable-next-line canonical/no-restricted-imports
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';

type InquiryButtonFunction = ({
  price,
  section,
  serviceType,
}: {
  price?: Service_productizedService$data['price'];
  section: InquiryEventSection;
  serviceType?: ProductizedServiceType | null;
}) => {
  onClick: (() => void) | undefined;
};

type InquiryContextProps = {
  getInquiryButtonProps: InquiryButtonFunction;
  getQuickHireButtonProps: InquiryButtonFunction;
  userProfileIsReceivingInquiries: boolean;
};

const WEEK_IN_DAYS = 7;
const MONTH_IN_DAYS = 28;
const convertLegacyDurationToJobDuration = ({
  duration,
}: {
  duration: number | null;
}): { amount: number; interval: 'DAY' | 'MONTH' | 'WEEK' } | null => {
  if (!duration) {
    return null;
  }

  if (duration < WEEK_IN_DAYS) {
    return {
      amount: duration,
      interval: 'DAY',
    };
  }

  if (duration < MONTH_IN_DAYS) {
    const adjustedWeekDuration = Math.round(duration / WEEK_IN_DAYS);
    return {
      amount: adjustedWeekDuration,
      interval: 'WEEK',
    };
  }

  const adjustedMonthDuration = Math.round(duration / MONTH_IN_DAYS);

  return {
    amount: adjustedMonthDuration,
    interval: 'MONTH',
  };
};

export const InquiryContext = createContext<InquiryContextProps>({
  getInquiryButtonProps: () => ({ disabled: false, onClick: () => {} }),
  getQuickHireButtonProps: () => ({ disabled: false, onClick: () => {} }),
  userProfileIsReceivingInquiries: false,
});

export const InquiryContextProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const router = useRouter();
  const toast = useToast();
  const userProfile = useUserProfile();
  const currentUser = useCurrentUser();
  const isInternalTraffic = useIsInternalTraffic();
  const startInquiry = useSearchParameter('startInquiry', 'boolean');
  const justSignedUp = useSearchParameter('justSignedUp', 'boolean');
  const demandPortfolioInquiryV2 = useFeatureFlag('demandPortfolioInquiryV2');
  const userProfileIsReceivingInquiries = Boolean(
    userProfile?.generalInquiryCtaIsVisible,
  );
  const { visitor } = useLazyLoadQuery<InquiryContextProviderQuery>(
    graphql`
      query InquiryContextProviderQuery {
        visitor {
          userAccount {
            ...InquiryContextProvider_userAccount
          }
        }
      }
    `,
    {},
  );

  const { track } = useAnalytics();

  const userAccount = useFragment<InquiryContextProvider_userAccount$key>(
    graphql`
      fragment InquiryContextProvider_userAccount on UserAccount {
        paidProjects(first: 1, filter: { source: GENERAL_INQUIRY }) {
          count
        }
        createdJobPostings(
          first: 1
          filter: {
            status: [APPROVED, ARCHIVED, PENDING, PENDING_REVISION, EXPIRED]
          }
        ) {
          count
        }
      }
    `,
    visitor.userAccount,
  );

  const [sendInquiry, isSendingInquiry] = useMutation<InquiryContextMutation>(
    graphql`
      mutation InquiryContextMutation($input: SendPaidProjectInquiryInput!) {
        sendPaidProjectInquiry(input: $input) {
          errors {
            __typename
            message
          }

          inquirySent
          userAccount {
            emailAddress
            profile {
              firstName
              lastName
            }
            ...InquiryContextProvider_userAccount
          }
        }
      }
    `,
  );

  const inquiryModalProps = useModal({
    isOpenByDefault: Boolean(currentUser?.userAccount) && startInquiry,
    modalName: 'ip_inquiry',
  });

  const inquiryModalPropsV2 = useModal({
    isOpenByDefault: Boolean(currentUser?.userAccount) && startInquiry,
    modalName: 'demand_request_to_work_modal',
  });

  const inquirySuccessModalProps = useModal({
    modalName: 'ip_inquiry_success',
  });

  const shortCodeConfirmationModalProps = useModal({
    modalName: 'ip_short_code_confirmation',
  });

  const completeSignUpModalProps = useModal({
    modalName: 'ip_complete_signup',
  });

  const getInquiryButtonProps = useCallback<InquiryButtonFunction>(
    ({ price, section, serviceType }) => ({
      'aria-label': 'Get in touch',
      onClick: userProfile?.visitorCanEdit
        ? () => {
            (demandPortfolioInquiryV2
              ? inquiryModalPropsV2
              : inquiryModalProps
            ).onOpen();
          }
        : () => {
            track(
              'button',
              demandPortfolioInquiryV2
                ? {
                    action: 'tapped',
                    button_name: 'demand_get_in_touch',
                    section,
                  }
                : {
                    action: 'tapped',
                    button_name: 'ip_inquiry',
                    section,
                    service_is_quick_hire: serviceType === 'BUY_NOW_OTHER',
                    service_pricing_type: price?.type,
                  },
            );
            if (isInternalTraffic && !currentUser?.userAccount) {
              completeSignUpModalProps.onOpen();
            } else {
              (demandPortfolioInquiryV2
                ? inquiryModalPropsV2
                : inquiryModalProps
              ).onOpen();
            }
          },
    }),
    [
      completeSignUpModalProps,
      currentUser?.userAccount,
      demandPortfolioInquiryV2,
      inquiryModalProps,
      inquiryModalPropsV2,
      isInternalTraffic,
      track,
      userProfile?.visitorCanEdit,
    ],
  );

  const getQuickHireButtonProps = useCallback<InquiryButtonFunction>(
    ({ price, section, serviceType }) => ({
      'aria-label': 'Buy service',
      onClick: () => {
        if (userProfile?.visitorCanEdit) {
          router.push(getContraUrl(`${router.asPath}/checkout`));
        } else if (isInternalTraffic && !currentUser?.userAccount) {
          completeSignUpModalProps.onOpen();
        } else {
          track('button', {
            action: 'tapped',
            button_name: 'ip_demand_initiate_service_purchase',
            section,
            service_is_quick_hire: serviceType === 'BUY_NOW_OTHER',
            service_pricing_type: price?.type,
          });
          router.push(getContraUrl(`${router.asPath}/checkout`));
        }
      },
    }),
    [
      completeSignUpModalProps,
      currentUser?.userAccount,
      isInternalTraffic,
      router,
      track,
      userProfile?.visitorCanEdit,
    ],
  );

  const value = useMemo(
    () => ({
      getInquiryButtonProps,
      getQuickHireButtonProps,
      userProfileIsReceivingInquiries,
    }),
    [
      getInquiryButtonProps,
      getQuickHireButtonProps,
      userProfileIsReceivingInquiries,
    ],
  );

  const defaultValues = useMemo(
    () => ({
      budget: '',
      duration: '',
      emailAddress: currentUser?.userAccount?.emailAddress ?? '',
      firstName: currentUser?.userAccount?.profile?.firstName ?? '',
      lastName: currentUser?.userAccount?.profile?.lastName ?? '',
      message: '',
      startDate: '',
    }),
    [currentUser],
  );

  const defaultValuesV2 = useMemo(
    () => ({
      budget: '',
      duration: '',
      emailAddress: currentUser?.userAccount?.emailAddress ?? '',
      firstName: currentUser?.userAccount?.profile?.firstName ?? '',
      lastName: currentUser?.userAccount?.profile?.lastName ?? '',
      message: '',
      messageGreeting: `Hi ${userProfile?.firstName} ${userProfile?.lastName}!`,
      title: '',
    }),
    [
      currentUser?.userAccount?.emailAddress,
      currentUser?.userAccount?.profile?.firstName,
      currentUser?.userAccount?.profile?.lastName,
      userProfile?.firstName,
      userProfile?.lastName,
    ],
  );

  const form = useForm<InquiryFormData>({
    defaultValues,
    resolver: yupResolver(inquiryFormValidationSchema),
  });

  const formV2 = useForm<InquiryFormV2Data>({
    defaultValues: defaultValuesV2,
    resolver: yupResolver(inquiryFormValidationSchemaV2),
  });

  const resetFormV2 = useCallback(
    (newValue?: typeof defaultValuesV2) => {
      formV2.reset(
        newValue ?? {
          budget: '',
          duration: '',
          emailAddress: currentUser?.userAccount?.emailAddress ?? '',
          firstName: currentUser?.userAccount?.profile?.firstName ?? '',
          lastName: currentUser?.userAccount?.profile?.lastName ?? '',
          message: '',
          messageGreeting: `Hi ${userProfile?.firstName} ${userProfile?.lastName}!`,
          title: '',
        },
      );
      formV2.trigger();
    },
    [
      currentUser?.userAccount?.emailAddress,
      currentUser?.userAccount?.profile?.firstName,
      currentUser?.userAccount?.profile?.lastName,
      formV2,
      userProfile?.firstName,
      userProfile?.lastName,
    ],
  );

  const hasActiveJobPostings = useMemo(() => {
    return userAccount?.createdJobPostings
      ? userAccount.createdJobPostings.count > 0
      : false;
  }, [userAccount]);

  /*
    Submission flows:
      Authenticated user:
        1. Submit inquiry
        2. Success modal
      Unauthenticated user:
        1. Submit inquiry (shortCode: true , creates user account if email not found)
        2. Short code confirmation modal (isOrganicTraffic: true necessary to allow unonboarded users to submit inquiries)
        3. Success modal
      Unauthenticated user with isInternalTraffic=true:
        1. Complete signup
        2. Onboards on CWA
        3. Submit inquiry modal
  */
  const onSubmit = useCallback(
    (data: InquiryFormData, onSuccess: () => void) => {
      sendInquiry({
        onCompleted: (response) => {
          const errors = response.sendPaidProjectInquiry.errors;

          if (errors?.length) {
            toast.error(
              `Something went wrong: ${
                errors[0]?.message ?? 'Unknown error'
              }. Please try again.`,
            );
          } else {
            onSuccess();
          }
        },
        onError: () => {
          toast.error(
            'Something went wrong while sending your message. Please try again.',
          );
        },
        variables: {
          input: {
            baseUrl: window.location.origin,
            budget: data.budget,
            contractorUserProfileId: userProfile?.id ?? '',
            duration: data.duration,
            emailAddress: data.emailAddress,
            firstName: data.firstName,
            inquirySource: 'INDEPENDENT_PORTFOLIO',
            // Allow and auto approve if organic traffic
            // and initial inquiry from existing user
            isOrganicTraffic:
              !isInternalTraffic || (currentUser?.userAccount && justSignedUp),
            jobPostingId: data.jobPostingId,
            lastName: data.lastName,
            message: data.message,
            sendShortCode: true,
            startDate: data.startDate,
          },
        },
      });
    },
    [
      currentUser?.userAccount,
      isInternalTraffic,
      justSignedUp,
      sendInquiry,
      toast,
      userProfile?.id,
    ],
  );

  const onSubmitV2 = useCallback(
    (data: InquiryFormV2Data, onSuccess: () => void) => {
      const budget = data.budget
        ? getBudgetRangeValuesFromString(data.budget)
        : null;

      const duration = PROJECT_DURATION_OPTIONS.find(
        (durationOption) => durationOption.id === data.duration,
      );

      sendInquiry({
        onCompleted: (response) => {
          const errors = response.sendPaidProjectInquiry.errors;

          if (errors?.length) {
            toast.error(
              `Something went wrong: ${
                errors[0]?.message ?? 'Unknown error'
              }. Please try again.`,
            );
          } else {
            if (response.sendPaidProjectInquiry.userAccount) {
              resetFormV2({
                ...defaultValuesV2,
                emailAddress:
                  response.sendPaidProjectInquiry.userAccount.emailAddress ??
                  '',
                firstName:
                  response.sendPaidProjectInquiry.userAccount.profile
                    ?.firstName ?? '',
                lastName:
                  response.sendPaidProjectInquiry.userAccount.profile
                    ?.lastName ?? '',
              });
            }

            onSuccess();
          }
        },
        onError: () => {
          toast.error(
            'Something went wrong while sending your message. Please try again.',
          );
        },
        variables: {
          input: {
            baseUrl: window.location.origin,
            contractorUserProfileId: userProfile?.id ?? '',
            emailAddress: data.emailAddress,
            firstName: data.firstName,
            inquirySource: 'INDEPENDENT_PORTFOLIO',
            // Allow and auto approve if organic traffic
            // and initial inquiry from existing user
            isOrganicTraffic:
              !isInternalTraffic || (currentUser?.userAccount && justSignedUp),
            jobInput: {
              budget: budget ?? undefined,
              duration:
                convertLegacyDurationToJobDuration({
                  duration: duration?.max ?? null,
                }) ?? undefined,
              title: data.title,
            },
            jobPostingId: data.jobPostingId,
            lastName: data.lastName,
            message: data.message,
            messageGreeting: data.messageGreeting,
            sendShortCode: true,
          },
        },
      });
    },
    [
      currentUser?.userAccount,
      defaultValuesV2,
      isInternalTraffic,
      justSignedUp,
      resetFormV2,
      sendInquiry,
      toast,
      userProfile?.id,
    ],
  );

  const onSendSuccessV2 = useCallback(
    (data: InquiryFormV2Data, onSuccess: VoidFunction) => {
      onSubmitV2(data, () => {
        inquiryModalPropsV2.onClose('primary');
        onSuccess();
        if (currentUser?.userAccount) {
          resetFormV2();
          inquirySuccessModalProps.onOpen();
        } else {
          shortCodeConfirmationModalProps.onOpen();
        }
      });
    },
    [
      currentUser?.userAccount,
      inquiryModalPropsV2,
      inquirySuccessModalProps,
      onSubmitV2,
      resetFormV2,
      shortCodeConfirmationModalProps,
    ],
  );

  const onCodeSuccessV2 = useCallback(() => {
    onSubmitV2(formV2.getValues(), () => {
      shortCodeConfirmationModalProps.onClose('primary');
      inquirySuccessModalProps.onOpen();
      if (currentUser?.userAccount) {
        resetFormV2();
      }
    });
  }, [
    currentUser?.userAccount,
    formV2,
    inquirySuccessModalProps,
    onSubmitV2,
    resetFormV2,
    shortCodeConfirmationModalProps,
  ]);

  return (
    <InquiryContext.Provider value={value}>
      {demandPortfolioInquiryV2 ? (
        <ShortCodeConfirmationModalV2
          emailAddress={formV2.getValues('emailAddress') ?? ''}
          isOpen={shortCodeConfirmationModalProps.isOpen}
          onSuccess={onCodeSuccessV2}
        />
      ) : (
        <ShortCodeConfirmationModal
          emailAddress={form.getValues('emailAddress') ?? ''}
          isOpen={shortCodeConfirmationModalProps.isOpen}
          onCancel={() => {
            shortCodeConfirmationModalProps.onClose('secondary');
            inquiryModalProps.onOpen();
          }}
          onSuccess={() => {
            onSubmit(form.getValues(), () => {
              shortCodeConfirmationModalProps.onClose('primary');
              form.reset(defaultValues);
              inquirySuccessModalProps.onOpen();
            });
          }}
        />
      )}

      {demandPortfolioInquiryV2 ? (
        <InquiryModalV2
          defaultValues={defaultValuesV2}
          form={formV2}
          hasActiveJobPostings={hasActiveJobPostings}
          isSendingInquiry={isSendingInquiry}
          {...inquiryModalPropsV2}
          onSubmit={onSendSuccessV2}
        />
      ) : (
        <InquiryModal
          defaultValues={defaultValues}
          form={form}
          hasActiveJobPostings={hasActiveJobPostings}
          isSendingInquiry={isSendingInquiry}
          {...inquiryModalProps}
          onSubmit={(data) => {
            onSubmit(data, () => {
              inquiryModalProps.onClose('primary');
              if (currentUser?.userAccount) {
                form.reset(defaultValues);
                inquirySuccessModalProps.onOpen();
              } else {
                shortCodeConfirmationModalProps.onOpen();
              }
            });
          }}
        />
      )}

      {demandPortfolioInquiryV2 ? (
        <InquirySuccessModalV2 {...inquirySuccessModalProps} />
      ) : (
        <InquirySuccessModal
          isFirstInquiry={
            userAccount ? userAccount?.paidProjects?.count <= 1 : true
          }
          {...inquirySuccessModalProps}
        />
      )}

      <CompleteSignUpModal {...completeSignUpModalProps} />

      {children}
    </InquiryContext.Provider>
  );
};
