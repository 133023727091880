import { object, string } from 'yup';

export const inquiryFormValidationSchema = object().shape({
  budget: string().trim(),
  duration: string().trim(),
  emailAddress: string()
    .email('You must provide a valid email address.')
    .required('An email address is required.'),
  firstName: string().trim().required('You must provide your first name.'),
  lastName: string().trim().required('You must provide your last name.'),
  message: string().trim().required('A message is required.'),
  startDate: string().trim(),
});

export const inquiryFormValidationSchemaV2 = object().shape({
  budget: string().trim().required(),
  duration: string().trim().required(),
  emailAddress: string()
    .email('You must provide a valid email address.')
    .required('An email address is required.'),
  firstName: string().trim().required('You must provide your first name.'),
  lastName: string().trim().required('You must provide your last name.'),
  message: string().trim().required('A message is required.'),
  messageGreeting: string().trim().required('A message greeting is required.'),
  title: string().trim().required('A title is required.'),
});
