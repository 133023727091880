import {
  type StartDateSelectOptions,
  type StartDateSelectProps,
} from './types.js';
import { SelectMenu } from '@/components/SelectMenu/SelectMenu.js';
import { TextField } from '@/components/TextField/TextField.js';
import { styled } from '@/stitches/index.js';
import { useSelect } from 'downshift';

const StyledTextField = styled(TextField, {
  '&&& > button > span:first-child': {
    margin: '0 0 $6 $16',
  },
  boxShadow: '$basic',
});

export const START_DATE_OPTIONS: StartDateSelectOptions[] = [
  {
    id: 'IMMEDIATELY',
    title: 'Immediately',
  },
  {
    id: 'SOON',
    title: 'In 1 to 2 weeks',
  },
  {
    id: 'LATER',
    title: 'More than 2 weeks from now',
  },
];

export const StartDateSelect = ({
  disabled = false,
  hasError,
  initialSelectedItem,
  onSelection,
}: StartDateSelectProps) => {
  const {
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    selectedItem: selectedTimeline,
  } = useSelect({
    initialSelectedItem,
    items: START_DATE_OPTIONS,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!disabled && selectedItem?.id !== undefined) {
        onSelection(selectedItem);
      }
    },
  });

  return (
    <StyledTextField hasError={hasError}>
      <TextField.Label {...getLabelProps()}>Ideal Start Date</TextField.Label>

      <TextField.InputGroup
        aria-disabled={disabled}
        as="button"
        type="button"
        {...getToggleButtonProps()}
      >
        <TextField.SelectionText
          isDisabled={disabled}
          isPlaceholder={!selectedTimeline}
        >
          {selectedTimeline ? selectedTimeline.title : '-Select-'}
        </TextField.SelectionText>

        {disabled ? (
          <TextField.Disabled />
        ) : (
          <TextField.Chevron isOpen={isOpen} />
        )}
      </TextField.InputGroup>
      {disabled ? null : (
        <TextField.Menu>
          <SelectMenu>
            <SelectMenu.Dialog {...getMenuProps({})}>
              {isOpen && (
                <SelectMenu.List>
                  {START_DATE_OPTIONS.map((item, index) => {
                    return (
                      <SelectMenu.Item
                        isHighlighted={highlightedIndex === index}
                        key={item.id}
                        {...getItemProps({ index, item })}
                      >
                        {item.title}
                      </SelectMenu.Item>
                    );
                  })}
                </SelectMenu.List>
              )}
            </SelectMenu.Dialog>
          </SelectMenu>
        </TextField.Menu>
      )}
    </StyledTextField>
  );
};
