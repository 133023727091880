import { Modal } from '@/components/ModalV2/Modal.js';
import { PlatformButton } from '@/components/PlatformButton/PlatformButton.js';
import { useCurrentUser } from '@/hooks/useCurrentUser.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import { type PropertyLibrary } from '@/services/analytics/propertyLibrary.js';
import { styled } from '@/stitches/index.js';
import { getContraUrl } from '@/utilities/getContraUrl.js';
import { localImageLoader } from '@/utilities/localImageLoader.js';
import Image from 'next/image';

const ImageContainer = styled('div', {
  textAlign: 'center',
  width: '100%',
});

const Container = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
});

const Title = styled('div', {
  fontSize: '$23',
  fontWeight: 600,
  lineHeight: '$32',
  marginBottom: '$4',
  marginTop: '$12',
});

const Description = styled('div', {
  color: '$gray70',
  fontSize: '$14',
  fontWeight: 400,
  lineHeight: '$20',
  marginBottom: '$24',
});

export const InquirySuccessModalV2 = ({
  isOpen,
  onClose,
}: {
  readonly isOpen: boolean;
  readonly onClose: (cta: PropertyLibrary['cta'] | null) => void;
}) => {
  const userProfile = useUserProfile();
  const currentUser = useCurrentUser();
  const hasCompletedOnboarding =
    currentUser?.userAccount?.userGroups.includes(
      'CLIENT_ONBOARDING_COMPLETE',
    ) ?? false;

  return (
    <Modal.Root open={isOpen}>
      <Modal.Content>
        <Modal.Close onClick={() => onClose('close')} />
        <Modal.Body>
          <Container>
            <ImageContainer>
              <Image
                alt="Request sent"
                height={250}
                loader={localImageLoader}
                src="/images/inquiry-success-banner.webp"
                width={420}
              />
            </ImageContainer>

            <Title>
              Your request was sent to {userProfile?.firstName}{' '}
              {userProfile?.lastName}!
            </Title>

            <Description>
              You'll receive an email when the independent responds
            </Description>
            <PlatformButton
              as="a"
              href={
                hasCompletedOnboarding
                  ? getContraUrl('/client/home')
                  : getContraUrl('/log-in')
              }
              onClick={() => onClose('primary')}
              variant="primary"
            >
              {hasCompletedOnboarding
                ? 'Continue'
                : 'Complete your hiring profile'}
            </PlatformButton>
          </Container>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
