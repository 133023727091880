import {
  type JobPostingSelectQuery,
  type JobPostingSelectQuery$data,
} from '@/__generated__/relay/JobPostingSelectQuery.graphql.js';
import { AddVerticalFades } from '@/components/AddVerticalFades/AddVerticalFades.js';
import { Avatar } from '@/components/Avatar/Avatar.js';
import { SelectMenu } from '@/components/SelectMenu/SelectMenu.js';
import { TextField } from '@/components/TextField/TextField.js';
import { styled } from '@/stitches/index.js';
import { useMount } from '@contra/react-hooks/useMount';
import { JobOutlineIcon } from '@contra/icons/icons';
import { useSelect } from 'downshift';
import { type MouseEvent, useCallback } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

export type JobPostingSelectOption = NonNullable<
  NonNullable<JobPostingSelectQuery$data['visitor']>['userAccount']
>['createdJobPostings']['edges'][number]['node'];

type JobPostingSelectProps = {
  readonly disabled?: boolean;
  readonly hasError?: boolean;
  readonly initialSelectedJobPostingId?: string;
  readonly onSelection: (posting: JobPostingSelectOption | null) => void;
  readonly outlined?: boolean;
};

const DetachPostingMenuItem = styled('li', {
  ':hover': {
    backgroundColor: '$gray10',
  },
  alignItems: 'center',
  backgroundColor: '$white',
  borderRadius: '0 0 $10 $10',
  borderTop: '$1 solid $gray30',
  bottom: '0',
  boxShadow: '$basic10',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '1rem',
  justifyContent: 'spaceBetween',
  lineHeight: '1.5',
  padding: '$12 $16',
  position: 'sticky',
  width: '100%',
  zIndex: '1',
});

const InputItemContainer = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '$16',
});

const OrganizationLabel = styled('span', {
  color: '$uiBlackHighEmphasis',
});

const StyledTextField = styled(TextField, {
  '&&& > button > span:first-child': {
    margin: '0 0 $6 $16',
  },
  border: 'none',
  borderRadius: '$8',
  boxShadow: '$basic40',
});

const JopPostingIcon = ({
  jobPosting,
}: {
  readonly jobPosting: JobPostingSelectOption;
}) => {
  return (
    <>
      {jobPosting.organization?.logoImage ? (
        <Avatar
          height={24}
          uid={jobPosting.organization.logoImage.uid}
          width={24}
        />
      ) : (
        <JobOutlineIcon size={24} />
      )}
    </>
  );
};

export const JobPostingSelect = ({
  disabled = false,
  hasError,
  initialSelectedJobPostingId,
  onSelection,
  outlined = false,
}: JobPostingSelectProps) => {
  const data = useLazyLoadQuery<JobPostingSelectQuery>(
    graphql`
      query JobPostingSelectQuery($skip: Boolean!) {
        visitor @skip(if: $skip) {
          userAccount {
            id
            createdJobPostings(
              filter: {
                status: [APPROVED, ARCHIVED, PENDING, PENDING_REVISION, EXPIRED]
              }
            ) {
              edges {
                node {
                  id
                  title
                  startTimeframe
                  minDuration
                  maxDuration
                  budget {
                    feeMax
                    feeMin
                    type
                  }
                  organization {
                    id
                    name
                    logoImage {
                      uid
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { skip: disabled },
  );

  const jobPostings: JobPostingSelectOption[] =
    data?.visitor?.userAccount?.createdJobPostings.edges.map(
      ({ node }) => node,
    ) ?? [];

  const initialSelectedJobPosting: JobPostingSelectOption | undefined =
    jobPostings.find((posting) => posting.id === initialSelectedJobPostingId);

  useMount(() => {
    if (!disabled && initialSelectedJobPosting) {
      onSelection(initialSelectedJobPosting);
    }
  });

  const {
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    reset,
    selectedItem: selectedJobPosting,
  } = useSelect({
    initialSelectedItem: initialSelectedJobPosting,
    items: jobPostings,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!disabled && selectedItem?.id !== undefined) {
        onSelection(selectedItem);
      }
    },
  });

  const onDetachJobPosting = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      reset();
      onSelection(null);
    },
    [onSelection, reset],
  );

  const disableInputs = jobPostings.length === 0 || disabled;

  const nullPostingLabel =
    data.visitor && jobPostings.length === 0
      ? 'No Job Postings Available'
      : '-Select-';

  const Container = outlined ? TextField : StyledTextField;

  return (
    <Container hasError={hasError}>
      <TextField.Label {...getLabelProps()}>
        Relevant Opportunity (Optional)
      </TextField.Label>

      <TextField.InputGroup
        aria-disabled={disableInputs}
        as="button"
        type="button"
        {...getToggleButtonProps()}
      >
        <TextField.SelectionText isPlaceholder={!selectedJobPosting}>
          {selectedJobPosting ? (
            <InputItemContainer>
              <JopPostingIcon jobPosting={selectedJobPosting} />
              <OrganizationLabel>{selectedJobPosting.title}</OrganizationLabel>
            </InputItemContainer>
          ) : (
            nullPostingLabel
          )}
        </TextField.SelectionText>

        {disableInputs ? (
          <TextField.Disabled />
        ) : (
          <TextField.Chevron isOpen={isOpen} />
        )}
      </TextField.InputGroup>

      {disableInputs ? null : (
        <TextField.Menu>
          <SelectMenu>
            <SelectMenu.Dialog {...getMenuProps({})}>
              {isOpen && (
                <AddVerticalFades fadeHeight={80}>
                  <SelectMenu.List
                    css={{ maxHeight: '$240', overflowY: 'scroll' }}
                  >
                    {jobPostings.map((item, index) => {
                      return (
                        <SelectMenu.Item
                          isHighlighted={highlightedIndex === index}
                          key={item.id}
                          {...getItemProps({ index, item })}
                        >
                          <InputItemContainer>
                            <JopPostingIcon jobPosting={item} />
                            <OrganizationLabel>{item.title}</OrganizationLabel>
                          </InputItemContainer>
                        </SelectMenu.Item>
                      );
                    })}
                    <DetachPostingMenuItem
                      key="remove-job-posting"
                      onClick={onDetachJobPosting}
                    >
                      I don&apos;t want to link an opportunity
                    </DetachPostingMenuItem>
                  </SelectMenu.List>
                </AddVerticalFades>
              )}
            </SelectMenu.Dialog>
          </SelectMenu>
        </TextField.Menu>
      )}
    </Container>
  );
};
