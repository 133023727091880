import { CodeInputContainer } from './CodeInput.styles.js';
import { type CodeInputProps, type InputElements } from './types.js';
import {
  arrayOfLength,
  BLANK_PLACEHOLDER,
  buildInputValue,
  buildOutputCode,
  CODE_REGEX,
  DEFAULT_PLACEHOLDER_CHARACTER,
  filterValueByRegex,
  getInputCursors,
  INPUT_ID_PREFIX,
  replaceAt,
} from './utils.js';
import {
  type ChangeEvent,
  type ClipboardEvent,
  type FocusEvent,
  type KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const onInputFocus = (event: FocusEvent<HTMLInputElement>) => {
  const { target } = event;
  // select current value
  target.select();
};

/**
 * Component for handling code-entry - such as inputting an authentication token or
 * one-time password (OTP). Manages focus between elements.
 */
export const CodeInput = ({
  autoFocus,
  className,
  codeLength = 6,
  filterRegex = CODE_REGEX,
  initialValue,
  isDisabled = false,
  onChange,
  onComplete,
  placeholderCharacter = DEFAULT_PLACEHOLDER_CHARACTER,
}: CodeInputProps) => {
  const defaultCode = BLANK_PLACEHOLDER.repeat(codeLength);
  const inputReferences = useRef<InputElements>({});
  const completeFiredCode = useRef<string | null>(null);
  const [currentCode, setCurrentCode] = useState(initialValue ?? defaultCode);

  const resetCode = useCallback(() => {
    setCurrentCode(defaultCode);
  }, [defaultCode]);

  const canFireComplete = (currentCodeAttempt: string) => {
    if (currentCodeAttempt !== completeFiredCode.current) {
      // store updated code value
      completeFiredCode.current = currentCodeAttempt;
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (onChange) {
      onChange(buildOutputCode(currentCode));
    }

    // if the code is complete run onComplete, blur inputs
    if (currentCode.replace(BLANK_PLACEHOLDER, '').length === codeLength) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }

      // To avoid unintentional re-firing of this effect, store the code that was
      // last fired in the onComplete handler.
      if (canFireComplete(currentCode)) {
        onComplete(buildOutputCode(currentCode), resetCode);
      }
    }
  }, [codeLength, currentCode, onChange, onComplete, resetCode]);

  const onInputPaste = (event: ClipboardEvent<HTMLDivElement>) => {
    // This prevents the onChange from being fired in certain browsers, after the paste event
    event.preventDefault();
    const pastedCode = event.clipboardData.getData('text');
    const filteredValue = filterValueByRegex(pastedCode, filterRegex);
    const trimmedValue = filteredValue.slice(0, codeLength);

    setCurrentCode(trimmedValue);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id: currentInputId, value } = event.target;
    const currentInputString = currentInputId.replace(INPUT_ID_PREFIX, '');
    const currentInputNumber = Number.parseInt(currentInputString, 10);
    const {
      currentInputElement,
      isLastInput,
      nextInputElement,
      previousInputElement,
    } = getInputCursors(inputReferences.current, currentInputId, codeLength);
    const filteredValue = filterValueByRegex(value, filterRegex);

    setCurrentCode((codeString) => {
      return replaceAt(codeString, currentInputNumber, filteredValue);
    });

    // if we haven't lost focus because we're done, move the cursor
    if (document.activeElement === currentInputElement) {
      // handle moving the cursor
      if (value === '') {
        previousInputElement?.focus();
      } else if (!isLastInput && filteredValue !== BLANK_PLACEHOLDER) {
        // move to the next element if the character entered was valid
        nextInputElement?.focus();
      }
    }
  };

  const onInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const { currentTarget, key } = event;
    const { id: inputId } = currentTarget;
    const { currentInputElement, nextInputElement, previousInputElement } =
      getInputCursors(inputReferences.current, inputId, codeLength);

    switch (key) {
      case 'Tab':
        // Allow default behavior to take over here
        break;
      case 'ArrowLeft':
        previousInputElement?.focus();
        break;
      case 'ArrowRight':
        nextInputElement?.focus();
        break;
      case 'Backspace':
        if (currentInputElement?.value === '') {
          previousInputElement?.focus();
        }

        break;
      default:
        break;
    }
  };

  const onFormClick = () => {
    // if the code is empty, force first input
    if (!buildOutputCode(currentCode)) {
      const { firstInputElement } = getInputCursors(
        inputReferences.current,
        `${INPUT_ID_PREFIX}0`,
        codeLength,
      );
      firstInputElement?.focus();
    }
  };

  return (
    <CodeInputContainer
      // eslint-disable-next-line react/forbid-component-props
      className={className}
      onClick={onFormClick}
      onPaste={onInputPaste}
    >
      {arrayOfLength(codeLength).map((index) => (
        <input
          aria-label={index === 0 ? 'Short Code' : undefined}
          autoCapitalize="on"
          autoComplete="off"
          autoFocus={autoFocus && index === 0}
          disabled={isDisabled}
          id={`${INPUT_ID_PREFIX}${index}`}
          key={`codeInput-${index}`}
          maxLength={1}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onKeyUp={onInputKeyUp}
          placeholder={placeholderCharacter}
          ref={(node) => {
            if (node) {
              inputReferences.current[`${INPUT_ID_PREFIX}${index}`] = node;
            }
          }}
          value={buildInputValue(currentCode[index] ?? '')}
        />
      ))}
    </CodeInputContainer>
  );
};
