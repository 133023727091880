import { LoadingSpinnerIcon } from '@/components/Icons/LoadingSpinnerIcon.js';
import { keyframes, styled, theme } from '@/stitches/index.js';

type LoaderProps = {
  readonly height?: number;
  readonly width?: number;
};

const Rotate = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const LoaderContainer = styled('div', {
  animation: `${Rotate} 1s linear infinite`,
  display: 'flex',
});

const StyledLoadingSpinner = styled(LoadingSpinnerIcon, {
  '& > path': {
    stroke: theme.colors.defaultFont,
  },
});

export const Loader = ({ height = 28, width = 28, ...props }: LoaderProps) => {
  return (
    <LoaderContainer>
      <StyledLoadingSpinner
        height={height}
        width={width}
        {...props}
      />
    </LoaderContainer>
  );
};
