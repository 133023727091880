import { InquiryForm } from './InquiryForm.js';
import { type InquiryFormData } from './types.js';
import { Avatar } from '@/components/Avatar/Avatar.js';
import { Modal } from '@/components/Modal/Modal.js';
import { type UserProfile } from '@/contexts/UserProfileContext.js';
import { useCurrentUser } from '@/hooks/useCurrentUser.js';
import { useIPUtmParameters } from '@/hooks/useIPUtmParameters.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import { type PropertyLibrary } from '@/services/analytics/propertyLibrary.js';
import { styled } from '@/stitches/index.js';
import { getContraUrl } from '@/utilities/getContraUrl.js';
import { getLinkWithUtms } from '@/utilities/getLinkWithUtms.js';
import NextImage from 'next/legacy/image';
import { type UseFormReturn } from 'react-hook-form';

const Anchor = styled('a', {
  '&:hover': {
    color: '$uiBlackHighEmphasis',
  },
  borderBottomColor: '$uiBlackMediumEmphasis',
  borderBottomStyle: 'solid',
  borderBottomWidth: '$1',
  marginBottom: '$1',
});

const InquiryModalHeaderLayout = styled('div', {
  '@bp0': {
    flexDirection: 'column',
    gap: '$12',
  },
  '@bp1': {
    flexDirection: 'row',
    gap: '0',
  },
  alignItems: 'center',
  display: 'flex',
  paddingBottom: '$0',
});

const Images = styled('div', {
  alignItems: 'center',
  display: 'flex',
  marginRight: '$12',
});

const InquiryModalHeaderAvatar = styled('div', {
  border: '$borderWidths$1 solid $transparent',
  borderRadius: '50%',
  height: '$66',
  overflow: 'hidden',
  width: '$66',
});

const InquiryModalHeaderLogo = styled('div', {
  alignItems: 'center',
  background: 'white',
  border: '$borderWidths$1 solid $gray30',
  borderRadius: '50%',
  display: 'flex',
  height: '$66',
  justifyContent: 'center',
  marginLeft: '-$16',
  overflow: 'hidden',
  padding: '$2',
  position: 'relative',
  width: '$66',
});

const InquiryModalHeader = ({
  userProfile,
}: {
  readonly userProfile: UserProfile;
}) => {
  const logoImageUrl =
    userProfile?.brandCustomization?.logo?.logoImage?.uid ?? null;

  const avatar = userProfile.avatarImage ?? null;

  return (
    <InquiryModalHeaderLayout>
      <Images>
        <InquiryModalHeaderAvatar>
          {avatar ? (
            <Avatar
              height={66}
              rounded
              uid={avatar.uid}
              width={66}
            />
          ) : null}
        </InquiryModalHeaderAvatar>
        {logoImageUrl && (
          <InquiryModalHeaderLogo>
            <NextImage
              alt={`${userProfile?.firstName} ${userProfile?.lastName}'s logo`}
              height={62}
              src={logoImageUrl}
              width={62}
            />
          </InquiryModalHeaderLogo>
        )}
      </Images>
      <Modal.Title>Want to work with {userProfile?.firstName}?</Modal.Title>
    </InquiryModalHeaderLayout>
  );
};

export const InquiryModal = ({
  defaultValues,
  form,
  hasActiveJobPostings,
  isOpen,
  isSendingInquiry,
  onClose,
  onSubmit,
}: {
  readonly defaultValues: InquiryFormData;
  readonly form: UseFormReturn<InquiryFormData>;
  readonly hasActiveJobPostings: boolean;
  readonly isOpen: boolean;
  readonly isSendingInquiry: boolean;
  readonly onClose: (cta: PropertyLibrary['cta'] | null) => void;
  readonly onSubmit: (data: InquiryFormData) => void;
}) => {
  const currentUser = useCurrentUser();
  const userProfile = useUserProfile();
  const utms = useIPUtmParameters('inquiry_form_description');

  if (!userProfile) return null;

  return (
    <Modal.Root open={isOpen}>
      <Modal.Content
        css={{
          '@bp0': { paddingTop: '$36' },
          '@bp3': { paddingTop: '$48  ' },
          width: '100%',
        }}
      >
        <Modal.Body>
          {userProfile?.visitorCanEdit ? (
            <Modal.Title>
              This is what clients see when they get in touch with you
            </Modal.Title>
          ) : (
            <InquiryModalHeader userProfile={userProfile} />
          )}

          <Modal.Description>
            {currentUser?.userAccount ? (
              userProfile?.visitorCanEdit ? (
                <span>
                  Inbound requests from clients can be found in your{' '}
                  <Anchor
                    href={getLinkWithUtms({
                      url: getContraUrl('messages'),
                      utms,
                    })}
                    rel="noreferrer"
                    target="_blank"
                  >
                    messages.
                  </Anchor>
                </span>
              ) : (
                'Fill out the form below and we’ll send a message on your behalf.'
              )
            ) : (
              <span>
                {userProfile?.firstName} will receive your message on{' '}
                <Anchor
                  href={getLinkWithUtms({ url: getContraUrl(), utms })}
                  rel="noreferrer"
                  target="_blank"
                >
                  Contra
                </Anchor>
              </span>
            )}
          </Modal.Description>
          <InquiryForm
            form={form}
            hasActiveJobPostings={hasActiveJobPostings}
            isSubmitting={isSendingInquiry}
            onClose={(cta: PropertyLibrary['cta'] | null) => {
              form.reset(defaultValues);
              onClose(cta);
            }}
            onSubmit={onSubmit}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
