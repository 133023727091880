import { usePostHog } from '@/hooks/usePostHog.js';
import { logger } from '@/services/logger.js';
import {
  type FeatureFlags,
  getDefaultFeatureFlags,
} from '@/utilities/getDefaultFeatureFlags.js';
import { storage } from '@/utilities/storage.js';
import { safeStringify } from '@contra/utilities/safeStringify';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const log = logger.child({
  namespace: 'useFeatureFlag',
});

const isFeatureFlag = (flag: string): flag is keyof FeatureFlags => {
  return Object.prototype.hasOwnProperty.call(getDefaultFeatureFlags(), flag);
};

type ExtractExperimentKeys<T> = {
  [J in keyof T]: T[J] extends 'control' | 'test' ? J : never;
}[keyof T];

const isExperimentKey = (
  key: string,
): key is ExtractExperimentKeys<FeatureFlags> => {
  const flags = getDefaultFeatureFlags();
  const experimentKeys: string[] = [];

  for (const [flagKey, value] of Object.entries(flags)) {
    if (typeof value === 'string') {
      experimentKeys.push(flagKey);
    }
  }

  return experimentKeys.includes(key);
};

/**
 * This is a temporary fallback for backwards compatibility.
 * This will be removed in future iterations.
 */
const testFeatureFlagFallbackValues = {
  ...getDefaultFeatureFlags(),
} as FeatureFlags;

/**
 * Use this instead of "usePostHog".
 * This hook enforces better typing and gives some additional convenience options.
 * @see https://www.notion.so/contrahq/Feature-Flags-e8a3301a4dd04201929fa0d58c34953f
 */
// eslint-disable-next-line complexity
export const useFeatureFlag = <
  K extends keyof FeatureFlags = keyof FeatureFlags,
>(
  name: K,
): FeatureFlags[K] => {
  const { featureFlags, warnings } = usePostHog();

  const sessionStorageFlags: FeatureFlags = JSON.parse(
    storage('session').getItem('CONTRA_SESSION_OVERRIDE_FEATURE_FLAGS') ?? '{}',
  );

  if (publicRuntimeConfig.explicitFeatureFlags) {
    const sessionStorageFlag = sessionStorageFlags[name];

    if (sessionStorageFlag === undefined) {
      const warningMessage = 'accessed unset feature flag "' + name + '"';

      if (!warnings?.current.includes(warningMessage)) {
        log.warn(warningMessage);

        warnings?.current.push(warningMessage);
      }
    } else {
      return sessionStorageFlag;
    }

    const testFeatureFlagFallbackValue = testFeatureFlagFallbackValues[name];

    if (testFeatureFlagFallbackValue !== undefined) {
      const warningMessage =
        'using hardcoded fallback value {' +
        name +
        ': ' +
        safeStringify(testFeatureFlagFallbackValue) +
        '}';

      if (!warnings?.current.includes(warningMessage)) {
        log.warn(warningMessage);

        warnings?.current.push(warningMessage);
      }

      return testFeatureFlagFallbackValue;
    }

    throw new Error('Accessed unknown feature flag "' + name + '"');
  }

  const flags: FeatureFlags = {
    ...getDefaultFeatureFlags(),
    ...featureFlags,
  };

  for (const [
    sessionStorageFlagName,
    sessionStorageFlagValue,
  ] of Object.entries(sessionStorageFlags)) {
    if (isFeatureFlag(sessionStorageFlagName)) {
      if (flags[sessionStorageFlagName] !== sessionStorageFlagValue) {
        if (isExperimentKey(sessionStorageFlagName)) {
          if (
            typeof sessionStorageFlagValue === 'string' &&
            (sessionStorageFlagValue === 'test' ||
              sessionStorageFlagValue === 'control')
          ) {
            flags[sessionStorageFlagName] = sessionStorageFlagValue;
          }
        } else if (typeof sessionStorageFlagValue === 'boolean') {
          flags[sessionStorageFlagName] = sessionStorageFlagValue;
        } else {
          flags[sessionStorageFlagName] = false;
        }

        log.info(
          '"%s" value was overridden to "%s" using CONTRA_SESSION_OVERRIDE_FEATURE_FLAGS',
          sessionStorageFlagName,
          sessionStorageFlagValue,
        );
      }
    } else {
      const warningMessage =
        'invalid attempt to override an unknown feature flag "' +
        sessionStorageFlagName +
        '" using CONTRA_SESSION_OVERRIDE_FEATURE_FLAGS';

      if (!warnings?.current.includes(warningMessage)) {
        log.warn(warningMessage);

        warnings?.current.push(warningMessage);
      }
    }
  }

  const flag = flags[name];

  if (flag === undefined) {
    log.warn('referenced unset feature flag "%s"', name);
  }

  return flag ?? false;
};
