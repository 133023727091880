/**
 * @generated SignedSource<<98d7eceae4fd17a43a2dff2d6e019904>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InquiryContextProviderQuery$variables = {};
export type InquiryContextProviderQuery$data = {
  readonly visitor: {
    readonly userAccount: {
      readonly " $fragmentSpreads": FragmentRefs<"InquiryContextProvider_userAccount">;
    } | null;
  };
};
export type InquiryContextProviderQuery = {
  response: InquiryContextProviderQuery$data;
  variables: InquiryContextProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InquiryContextProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Visitor",
        "kind": "LinkedField",
        "name": "visitor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "userAccount",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InquiryContextProvider_userAccount"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InquiryContextProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Visitor",
        "kind": "LinkedField",
        "name": "visitor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "userAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "source": "GENERAL_INQUIRY"
                    }
                  },
                  (v0/*: any*/)
                ],
                "concreteType": "UserAccountPaidProjectConnection",
                "kind": "LinkedField",
                "name": "paidProjects",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": "paidProjects(filter:{\"source\":\"GENERAL_INQUIRY\"},first:1)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "status": [
                        "APPROVED",
                        "ARCHIVED",
                        "PENDING",
                        "PENDING_REVISION",
                        "EXPIRED"
                      ]
                    }
                  },
                  (v0/*: any*/)
                ],
                "concreteType": "UserAccountCreatedJobPostingsConnection",
                "kind": "LinkedField",
                "name": "createdJobPostings",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": "createdJobPostings(filter:{\"status\":[\"APPROVED\",\"ARCHIVED\",\"PENDING\",\"PENDING_REVISION\",\"EXPIRED\"]},first:1)"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3f5fbfa4e3632efb5fb7a71c0c100f2",
    "id": null,
    "metadata": {},
    "name": "InquiryContextProviderQuery",
    "operationKind": "query",
    "text": "query InquiryContextProviderQuery {\n  visitor {\n    userAccount {\n      ...InquiryContextProvider_userAccount\n      id\n    }\n    id\n  }\n}\n\nfragment InquiryContextProvider_userAccount on UserAccount {\n  paidProjects(first: 1, filter: {source: GENERAL_INQUIRY}) {\n    count\n  }\n  createdJobPostings(first: 1, filter: {status: [APPROVED, ARCHIVED, PENDING, PENDING_REVISION, EXPIRED]}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff739acc613cb16e40e58e3fd61ba698";

export default node;
