import { type useConfirmShortCodeMutation$data } from '@/__generated__/relay/useConfirmShortCodeMutation.graphql.js';
import { CodeInput } from '@/components/CodeInput/CodeInput.js';
import { ContraConfettiIcon } from '@/components/Icons/ContraConfettiIcon.js';
import { Loader } from '@/components/Loader/Loader.js';
import { Modal } from '@/components/Modal/Modal.js';
import { PlatformButton } from '@/components/PlatformButton/PlatformButton.js';
import { useToast } from '@/components/Toasts/hooks/useToast.js';
import { useConfirmShortCode } from '@/hooks/useConfirmShortCode.js';
import { styled } from '@/stitches/index.js';
import { ChevronLeftIcon } from '@contra/icons/icons';
import { useCallback } from 'react';

type EmailShortCodeConfirmationContentProps = {
  readonly emailAddress: string;
  readonly isOpen: boolean;
  readonly onCancel: VoidFunction;
  readonly onSuccess: VoidFunction;
};

const ContraLoader = styled('div', {
  position: 'absolute',
  right: '$16',
  top: '$16',
});

const Span = styled('span', {
  color: '$brandBlack',
  fontSize: '$19',
  fontWeight: 600,
  lineHeight: '$28',
  marginBottom: '$16',
});

export const ShortCodeConfirmationModal = ({
  emailAddress,
  isOpen,
  onCancel,
  onSuccess,
}: EmailShortCodeConfirmationContentProps) => {
  const toast = useToast();
  const [confirmShortCode, confirmShortCodeLoading] = useConfirmShortCode();

  const handleCompleted = useCallback(
    (
      {
        confirmShortCode: { errors, visitor },
      }: useConfirmShortCodeMutation$data,
      callback: () => void,
    ) => {
      if (visitor) {
        toast.success('Code successfully verified', { role: 'alert' });
        onSuccess();
      } else {
        const errorMessage =
          errors?.[0]?.message ?? 'Failed to confirm authentication code.';
        toast.error(errorMessage, { role: 'alert' });
        callback();
      }
    },
    [onSuccess, toast],
  );

  const handleCodeInputComplete = useCallback(
    (code: string, resetCode: VoidFunction) => {
      confirmShortCode({
        input: {
          code,
          emailAddress,
        },
        onCompleted: (data) => {
          handleCompleted(data, resetCode);
        },
        onError: () => {
          toast.error(
            'Something went wrong while trying to confirm your code. Please try again.',
          );
          resetCode();
        },
      });
    },
    [confirmShortCode, emailAddress, handleCompleted, toast],
  );

  return (
    <Modal.Root open={isOpen}>
      <Modal.Content>
        <Modal.Body>
          {confirmShortCodeLoading ? (
            <ContraLoader>
              <Loader />
            </ContraLoader>
          ) : null}

          <ContraConfettiIcon
            height={54}
            width={54}
          />
          <Modal.Title>We emailed you a code</Modal.Title>
          <Modal.Description>
            Enter the verification code sent to: <br /> {emailAddress}
          </Modal.Description>

          <CodeInput
            autoFocus
            onComplete={handleCodeInputComplete}
          />

          <Modal.Divider />
          <Span>Didn&apos;t get anything? </Span>
          <PlatformButton
            onClick={onCancel}
            variant="secondary"
          >
            <ChevronLeftIcon /> Edit Email Address
          </PlatformButton>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
