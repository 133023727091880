import { BudgetRangeSelect } from './components/BudgetRangeSelect/BudgetRangeSelect.js';
import { getInputTypeFromBudget } from './components/BudgetRangeSelect/utilities.js';
import {
  JobPostingSelect,
  type JobPostingSelectOption,
} from './components/JobPostingSelect/JobPostingSelect.js';
import {
  PROJECT_DURATION_OPTIONS,
  ProjectDurationSelect,
} from './components/ProjectDurationSelect/ProjectDurationSelect.js';
import {
  START_DATE_OPTIONS,
  StartDateSelect,
} from './components/StartDateSelect/StartDateSelect.js';
import {
  Form,
  Row,
  StyledButton,
  StyledTextLink,
} from './InquiryForm.styles.js';
import { type InquiryFormData } from './types.js';
import { ContraTermsOfAgreement } from '@/components/ContraTermsOfAgreement/ContraTermsOfAgreement.js';
import { FormField } from '@/components/FormField/FormField.js';
import { FormSection } from '@/components/FormSection/FormSection.js';
import { ArmIcon } from '@/components/Icons/ArmIcon.js';
import { ContraLightbulbIcon } from '@/components/Icons/ContraLightbulbIcon.js';
import { Loader } from '@/components/Loader/Loader.js';
import { Modal } from '@/components/Modal/Modal.js';
import { TextField } from '@/components/TextField/TextField.js';
import { useCurrentUser } from '@/hooks/useCurrentUser.js';
import { useTrackFormProgress } from '@/hooks/useTrackFormProgress.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import {
  type InquiryFormSection,
  type PropertyLibrary,
} from '@/services/analytics/propertyLibrary.js';
import { getContraUrl } from '@/utilities/getContraUrl.js';
import { ErrorMessage } from '@hookform/error-message';
import { Suspense, useCallback, useState } from 'react';
import { type UseFormReturn } from 'react-hook-form';

type InquiryFormProps = {
  readonly form: UseFormReturn<InquiryFormData>;
  readonly hasActiveJobPostings: boolean;
  readonly isSubmitting: boolean;
  readonly onClose: (cta: PropertyLibrary['cta'] | null) => void;
  readonly onSubmit: (data: InquiryFormData) => void;
};

const getInquiryInputValuesByJobPosting = (
  jobPosting: JobPostingSelectOption,
) => {
  const feeMax = jobPosting.budget?.feeMax ?? undefined;
  const feeMin = jobPosting.budget?.feeMin ?? undefined;
  const type = jobPosting.budget?.type ?? undefined;

  const rate = getInputTypeFromBudget({
    feeMax,
    feeMin,
    type,
  });

  const duration = PROJECT_DURATION_OPTIONS.find(
    ({ max, min }) =>
      min === jobPosting?.minDuration && max === jobPosting?.maxDuration,
  );

  const startDate = START_DATE_OPTIONS.find(
    ({ id }) => id === jobPosting.startTimeframe,
  );

  return {
    budget: rate?.title,
    duration: duration?.title,
    startDate: startDate?.title,
  };
};

export const InquiryForm = ({
  form,
  hasActiveJobPostings,
  isSubmitting,
  onClose,
  onSubmit,
}: InquiryFormProps) => {
  const currentUser = useCurrentUser();
  const userProfile = useUserProfile();
  const trackFormProgress = useTrackFormProgress<InquiryFormSection>({
    formName: 'ip_inquiry',
  });
  const [key, setKey] = useState('');
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = form;

  const onFormChange = useCallback(() => {
    const {
      budget,
      duration,
      emailAddress,
      firstName,
      lastName,
      message,
      startDate,
    } = getValues();

    trackFormProgress({
      inquiry_details: [budget, duration, startDate],
      personal_details: [firstName, lastName, emailAddress],
      write_a_message: [message.length > 3],
    });
  }, [getValues, trackFormProgress]);

  const updateSelectedPosting = useCallback(
    (posting: JobPostingSelectOption | null) => {
      if (!posting) {
        setValue('jobPostingId', '');
        setValue('startDate', undefined);
        setValue('duration', undefined);
        setValue('budget', undefined);
        setKey('');
        return;
      }

      const { budget, duration, startDate } =
        getInquiryInputValuesByJobPosting(posting);
      setValue('startDate', startDate ?? '');
      setValue('duration', duration ?? '');
      setValue('budget', budget ?? '');
      setValue('jobPostingId', posting.id);
      setKey(posting.id);
    },
    [setValue],
  );

  const currentStartDate = START_DATE_OPTIONS.find(
    ({ title }) => title === getValues().startDate,
  );

  const currentDuration = PROJECT_DURATION_OPTIONS.find(
    ({ title }) => title === getValues().duration,
  );

  const isLinkedToJobPosting = Boolean(getValues().jobPostingId);
  const isDisabled = userProfile?.visitorCanEdit;

  return (
    <>
      <Form
        onChange={onFormChange}
        onSubmit={handleSubmit(onSubmit)}
      >
        {currentUser?.userAccount ? null : (
          <>
            <FormSection title="Personal details" />
            <Row>
              <FormField
                disabled={isDisabled}
                hasError={errors?.firstName !== undefined}
              >
                <TextField hasError={errors?.firstName !== undefined}>
                  <TextField.Label htmlFor="firstName">
                    First Name
                  </TextField.Label>
                  <TextField.InputGroup>
                    <TextField.Input
                      autoComplete="given-name"
                      id="firstName"
                      {...register('firstName')}
                    />
                  </TextField.InputGroup>
                </TextField>
                <FormField.Error>
                  <ErrorMessage
                    errors={errors}
                    name="firstName"
                  />
                </FormField.Error>
              </FormField>
              <FormField
                disabled={isDisabled}
                hasError={errors?.lastName !== undefined}
              >
                <TextField hasError={errors?.lastName !== undefined}>
                  <TextField.Label htmlFor="lastName">
                    Last Name
                  </TextField.Label>
                  <TextField.InputGroup>
                    <TextField.Input
                      autoComplete="family-name"
                      id="lastName"
                      {...register('lastName')}
                    />
                  </TextField.InputGroup>
                </TextField>
                <FormField.Error>
                  <ErrorMessage
                    errors={errors}
                    name="lastName"
                  />
                </FormField.Error>
              </FormField>
            </Row>
            <FormField
              disabled={isDisabled}
              hasError={errors?.emailAddress !== undefined}
            >
              <TextField hasError={errors?.emailAddress !== undefined}>
                <TextField.Label htmlFor="emailAddress">Email</TextField.Label>
                <TextField.InputGroup>
                  <TextField.Input
                    id="emailAddress"
                    {...register('emailAddress')}
                    type="email"
                  />
                </TextField.InputGroup>
              </TextField>
              <FormField.Error>
                <ErrorMessage
                  errors={errors}
                  name="emailAddress"
                />
              </FormField.Error>
            </FormField>
          </>
        )}
        <FormSection
          title="Write a Message"
          tooltip="Start a conversation to learn more about each others' expertise or needs."
        />
        <FormField
          disabled={isDisabled}
          hasError={errors?.message !== undefined}
        >
          <TextField hasError={errors?.message !== undefined}>
            <TextField.InputGroup>
              <TextField.TextArea
                aria-label="message"
                {...register('message')}
                css={{
                  resize: 'none',
                }}
                id="message"
                minRows={4}
                placeholder={`Start a conversation with ${userProfile?.firstName}...`}
              />
            </TextField.InputGroup>
          </TextField>
          <FormField.Help icon={ArmIcon}>
            Send a winning inquiry!{' '}
            <StyledTextLink
              href={getContraUrl(
                'p/LbeLkrmM-how-to-send-a-winning-inquiry-on-contra',
              )}
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more here
            </StyledTextLink>
          </FormField.Help>
          <FormField.Error>
            <ErrorMessage
              errors={errors}
              name="message"
            />
          </FormField.Error>
        </FormField>

        <FormSection
          optional
          title="Inquiry Details"
          tooltip="Fill in new inquiry details."
        />

        <Suspense
          fallback={
            <JobPostingSelect
              disabled
              onSelection={() => {}}
              outlined
            />
          }
        >
          {hasActiveJobPostings ? (
            <Row>
              <FormField hasError={errors?.jobPostingId !== undefined}>
                <JobPostingSelect
                  disabled={isDisabled}
                  onSelection={updateSelectedPosting}
                  outlined
                />
                <FormField.Error>
                  <ErrorMessage
                    errors={errors}
                    name="jobPostingId"
                  />
                </FormField.Error>
              </FormField>
            </Row>
          ) : null}
        </Suspense>

        <Row>
          <FormField hasError={errors?.startDate !== undefined}>
            <StartDateSelect
              disabled={isLinkedToJobPosting}
              hasError={errors?.startDate !== undefined}
              initialSelectedItem={currentStartDate}
              key={key}
              onSelection={(value) => setValue('startDate', value.title)}
            />
            <FormField.Error>
              <ErrorMessage
                errors={errors}
                name="startDate"
              />
            </FormField.Error>
          </FormField>
          <FormField hasError={errors?.duration !== undefined}>
            <ProjectDurationSelect
              disabled={isLinkedToJobPosting}
              hasError={errors?.duration !== undefined}
              initialSelectedItem={currentDuration}
              key={key}
              onSelection={(value) => setValue('duration', value.title)}
            />
            <FormField.Error>
              <ErrorMessage
                errors={errors}
                name="duration"
              />
            </FormField.Error>
          </FormField>
        </Row>
        <Row>
          <FormField hasError={errors?.budget !== undefined}>
            <BudgetRangeSelect
              disabled={isLinkedToJobPosting}
              hasError={errors?.budget !== undefined}
              initialSelection={getValues().budget ?? undefined}
              key={key}
              onSelection={(value) => setValue('budget', value.title)}
            />
            <FormField.Help icon={ContraLightbulbIcon}>
              Budget will likely change based on negotiations with the
              Independent(s)
            </FormField.Help>
            <FormField.Error>
              <ErrorMessage
                errors={errors}
                name="budget"
              />
            </FormField.Error>
          </FormField>
        </Row>
        <Modal.ButtonBox css={{ justifyContent: 'center', width: '100%' }}>
          <StyledButton
            onClick={() => {
              onClose('secondary');
            }}
            variant="secondary"
          >
            Cancel
          </StyledButton>
          <StyledButton
            css={{ marginLeft: '0 !important' }}
            disabled={isSubmitting || isDisabled}
            type="submit"
            variant="primary"
          >
            {isSubmitting ? <Loader /> : 'Send Message'}
          </StyledButton>
        </Modal.ButtonBox>
      </Form>
      <ContraTermsOfAgreement />
    </>
  );
};
