import { type UTMParameters } from '@/services/analytics/types.js';

export const getLinkWithUtms = ({
  url,
  utms,
}: {
  url: string;
  utms: Partial<UTMParameters>;
}) => {
  const parameters = new URLSearchParams(utms);
  return new URL(`${url}?${parameters.toString()}`).toString();
};
