import { keyframes, styled } from '@/stitches/index.js';
import { pxToRem } from '@/utilities/pxToRem.js';
import * as ModalPrimitive from '@radix-ui/react-dialog';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const fadeUp = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate(0, 1rem)',
  },
  '100%': {
    opacity: 1,
    transform: 'translate(0, 0)',
  },
});

const slideUp = keyframes({
  '0%': {
    transform: 'translate(0, 200%)',
  },
  '100%': {
    transform: 'translate(0, 0)',
  },
});

export const Overlay = styled(ModalPrimitive.Overlay, {
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 250ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  backgroundColor: '$overlay20',
  inset: 0,
  position: 'fixed',
  zIndex: '$modalBackdrop',
});

export const Container = styled('div', {
  display: 'flex',
  overflow: 'scroll',
  variants: {
    variant: {
      drawer: {
        alignItems: 'flex-end',
        height: '100vh',
      },
      modal: {
        alignItems: 'flex-start',
        height: '100%',
        justifyContent: 'center',
        maxHeight: '100%',
        padding: '$48 $16',
      },
      modalNew: {
        alignItems: 'flex-start',
        height: '100%',
        justifyContent: 'center',
        maxHeight: '100%',
        padding: '$48 $16',
      },
      modalSmol: {
        alignItems: 'flex-start',
        height: '100%',
        justifyContent: 'center',
        maxHeight: '100%',
        padding: '$48 $16',
      },
    },
  },
  zIndex: '$modalDialog',
});

export const Content = styled(ModalPrimitive.Content, {
  '&:focus': { outline: 'none' },
  backgroundColor: '$white',
  fontFamily: '$inter',
  position: 'relative',
  variants: {
    variant: {
      drawer: {
        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${slideUp} 250ms forwards`,
        },
        borderRadius: '$20 $20 0 0',
        bottom: 0,
        left: 0,
        padding: '$16 $18 $32',
        position: 'absolute',
        width: '100vw',
      },
      modal: {
        '@bp0': {
          padding: '$24 $16 $16 $16',
          width: '100%',
        },
        '@bp2': {
          maxWidth: '$800',
          padding: '$28 $24 $24 $24',
          width: pxToRem(540),
        },
        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${fadeUp} 250ms forwards`,
        },
        borderRadius: '$10',
        height: 'auto',
        margin: 'auto 0',
      },
      modalNew: {
        '@bp0': {
          padding: '$32 $16 $16 $16',
          width: '100%',
        },
        '@bp2': {
          maxWidth: '$800',
          padding: '$24 $24 $24 $24',
          width: pxToRem(704),
        },
        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${fadeUp} 250ms forwards`,
        },
        borderRadius: '$10',
        height: 'auto',
        margin: 'auto 0',
      },
      modalSmol: {
        '@bp0': {
          maxWidth: '$544',
          padding: '$24 $16 $16 $16',
          width: '100%',
        },
        '@bp2': {
          padding: '$24',
          width: '$544',
        },
        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${fadeUp} 250ms forwards`,
        },
        borderRadius: '$20',
        height: 'auto',
        margin: 'auto 0',
      },
    },
    // eslint-disable-next-line canonical/sort-keys
    fullImage: {
      true: {
        padding: '0 !important',
      },
    },
  },
});

export const Title = styled(ModalPrimitive.Title, {
  '@bp0': {
    fontSize: '$19',
    fontWeight: 600,
    letterSpacing: '-$0_25',
    lineHeight: 1.5,
  },
  '@bp2': {
    fontSize: '$19',
    fontWeight: 600,
    letterSpacing: '-$0.5',
  },
  alignItems: 'center',
  color: '$brandBlack',
  display: 'flex',
  fontFamily: '$inter',
  gap: '$8',
  margin: 0,
  variants: {
    variant: {
      modalSmol: {
        alignItems: 'center',
        display: 'inline-flex',
        fontSize: '$19',
        gap: '$8',
      },
    },
  },
});

export const Description = styled(ModalPrimitive.Description, {
  color: '$uiBlackMediumEmphasis',
  fontFamily: '$inter',
  fontSize: '$16',
  fontWeight: 400,
  lineHeight: 1.5,
  marginTop: '$12',
  textAlign: 'left',
});

export const CallToAction = styled('button', {
  '&:hover': {
    background: '$yellow10',
    cursor: 'pointer',
  },
  alignItems: 'center',
  background: '$brandYellow',
  borderRadius: '$25',
  color: '$brandBlack',
  display: 'flex',
  flexFlow: 'row nowrap',
  fontFamily: '$inter',
  fontSize: '$16',
  fontWeight: 600,
  height: '$48',
  justifyContent: 'center',
  lineHeight: '$24',
  padding: '$12 $24',
  textAlign: 'center',
  transition: 'background-color $basicDuration ease',
});

const CloseButtonStyles = {
  '& > svg': {
    color: '$gray60',
  },
  cursor: 'pointer',
  position: 'absolute',
  right: '$16',
  top: '$16',
  variants: {
    fullImage: {
      true: {
        '& > svg': {
          color: '$white',
        },
      },
    },
  },
  zIndex: '$modalDialog',
};

export const CloseButtonContainer = styled('button', CloseButtonStyles);

export const Close = styled(ModalPrimitive.Close, CloseButtonStyles);

export const Body = styled('div', {
  alignItems: 'flex-start',
  color: '$brandBlack',
  display: 'flex',
  flexFlow: 'column nowrap',
  marginTop: '-$8',
  textAlign: 'center',
  variants: {
    fullImage: {
      true: {
        '@bp0': {
          marginTop: '-$4',
          padding: '$24',
        },
        '@bp2': {
          marginTop: '-$4',
          padding: '$24 $44 $24',
        },
      },
    },
  },
});

// @deprecated Use button box instead
export const Buttons = styled('div', {
  '& > * + *': {
    marginTop: '$16',
  },
  '@bp0': {
    '& > *': {
      width: '100%',
    },
    margin: '$24 0 0',
  },
  '@bp1': {
    '& > *': {
      width: 'auto',
    },
    margin: '$32 0 0',
  },
  alignItems: 'center',
  display: 'flex',
  flexFlow: 'column nowrap',
  textAlign: 'center',
  width: '100%',
});

export const ButtonBox = styled('div', {
  '@bp0': {
    flexFlow: 'column-reverse',
    gap: '$16',
    width: '100%',
  },
  '@bp2': {
    flexFlow: 'row-reverse nowrap',
    gap: '$8',
    width: '100%',
  },
  alignItems: 'center',
  display: 'flex',
  marginTop: '$24',
});

export const Image = styled('div', {
  marginBottom: '$24',
  variants: {
    fullImage: {
      true: {
        borderTopLeftRadius: '$20',
        borderTopRightRadius: '$20',
        marginBottom: 0,
        overflow: 'hidden',
        width: '100%',
      },
    },
  },
  width: '100%',
});

export const Divider = styled('div', {
  borderTop: 'solid $gray30',
  borderWidth: '$1',
  margin: '$24 0',
  width: '100%',
});

export const ProgressWrapper = styled('div', {
  backgroundColor: '$gray30',
  borderTopLeftRadius: '$10',
  borderTopRightRadius: '$10',
  height: '$10',
  left: '$1',
  overflow: 'hidden',
  position: 'absolute',
  top: '0',
  width: 'calc(100% - $2)',
});

export const ProgressBar = styled('div', {
  '&::after': {
    background:
      'linear-gradient(90deg, #F3CA4D 0%, #FF989A 42.88%, #CBABFF 94.62%)',
    borderRadius: '$0 $10 $10 $0',
    content: '',
    height: '$10',
    inset: 0,
    position: 'absolute',
    transition: 'width 0.3s ease-in-out',
  },
  backgroundColor: '$gray30',
  height: '$8',
  position: 'relative',
  width: '0%',
});
