/**
 * @generated SignedSource<<fcbd37fdf667f34fa9f066dfee8e0742>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobBudgetInterval = "DAY" | "HOUR" | "MONTH" | "TWO_WEEK" | "WEEK" | "YEAR" | "%future added value";
export type JobBudgetType = "FIXED_PRICE" | "RATE" | "%future added value";
export type JobDurationInterval = "DAY" | "MONTH" | "WEEK" | "%future added value";
export type PaidProjectInquirySource = "DISCOVER" | "INDEPENDENT_PORTFOLIO" | "LISTS_MODAL" | "PROFILE" | "PROJECT" | "%future added value";
export type SendPaidProjectInquiryInput = {
  baseUrl?: ContraAPIScalars.SafeURL | null;
  budget?: string | null;
  calendarLink?: string | null;
  cc?: string | null;
  contractorUserProfileId?: string | null;
  duration?: string | null;
  emailAddress?: string | null;
  firstName?: string | null;
  inquirySource?: PaidProjectInquirySource | null;
  isOrganicTraffic?: boolean | null;
  jobId?: string | null;
  jobInput?: SendPaidProjectInquiryJobInput | null;
  jobPostingId?: string | null;
  lastName?: string | null;
  message: string;
  messageGreeting?: string | null;
  productizedServiceId?: string | null;
  redirectTo?: string | null;
  referrerUrl?: ContraAPIScalars.SafeURL | null;
  sendShortCode?: boolean | null;
  startDate?: string | null;
  timeline?: string | null;
};
export type SendPaidProjectInquiryJobInput = {
  budget?: JobBudgetInput | null;
  duration?: JobDurationInput | null;
  title: string;
};
export type JobBudgetInput = {
  estimatedHours?: number | null;
  feeMax?: any | null;
  feeMin: any;
  interval?: JobBudgetInterval | null;
  type: JobBudgetType;
};
export type JobDurationInput = {
  amount: number;
  interval: JobDurationInterval;
};
export type InquiryContextMutation$variables = {
  input: SendPaidProjectInquiryInput;
};
export type InquiryContextMutation$data = {
  readonly sendPaidProjectInquiry: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message: string;
    }> | null;
    readonly inquirySent: boolean;
    readonly userAccount: {
      readonly emailAddress: string | null;
      readonly profile: {
        readonly firstName: string | null;
        readonly lastName: string | null;
      };
      readonly " $fragmentSpreads": FragmentRefs<"InquiryContextProvider_userAccount">;
    } | null;
  };
};
export type InquiryContextMutation = {
  response: InquiryContextMutation$data;
  variables: InquiryContextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inquirySent",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailAddress",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InquiryContextMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendPaidProjectInquiryPayload",
        "kind": "LinkedField",
        "name": "sendPaidProjectInquiry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "userAccount",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InquiryContextProvider_userAccount"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InquiryContextMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendPaidProjectInquiryPayload",
        "kind": "LinkedField",
        "name": "sendPaidProjectInquiry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v9/*: any*/),
                "type": "CollaboratorNotFoundError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v9/*: any*/),
                "type": "CollaboratorRoleCountInvalidError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v9/*: any*/),
                "type": "OrganizationNotFoundError",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v9/*: any*/),
                "type": "ProductizedServiceCollaboratorNotFound",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v9/*: any*/),
                "type": "ReferBasicOpportunityUserNotFound",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v9/*: any*/),
                "type": "SubmitJoinOrganizationRequestError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "userAccount",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "source": "GENERAL_INQUIRY"
                    }
                  },
                  (v10/*: any*/)
                ],
                "concreteType": "UserAccountPaidProjectConnection",
                "kind": "LinkedField",
                "name": "paidProjects",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "paidProjects(filter:{\"source\":\"GENERAL_INQUIRY\"},first:1)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "status": [
                        "APPROVED",
                        "ARCHIVED",
                        "PENDING",
                        "PENDING_REVISION",
                        "EXPIRED"
                      ]
                    }
                  },
                  (v10/*: any*/)
                ],
                "concreteType": "UserAccountCreatedJobPostingsConnection",
                "kind": "LinkedField",
                "name": "createdJobPostings",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "createdJobPostings(filter:{\"status\":[\"APPROVED\",\"ARCHIVED\",\"PENDING\",\"PENDING_REVISION\",\"EXPIRED\"]},first:1)"
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aae044f11e1bf70456eb6329f108185b",
    "id": null,
    "metadata": {},
    "name": "InquiryContextMutation",
    "operationKind": "mutation",
    "text": "mutation InquiryContextMutation(\n  $input: SendPaidProjectInquiryInput!\n) {\n  sendPaidProjectInquiry(input: $input) {\n    errors {\n      __typename\n      message\n      ... on CollaboratorNotFoundError {\n        id\n      }\n      ... on CollaboratorRoleCountInvalidError {\n        id\n      }\n      ... on OrganizationNotFoundError {\n        id\n      }\n      ... on ProductizedServiceCollaboratorNotFound {\n        id\n      }\n      ... on ReferBasicOpportunityUserNotFound {\n        id\n      }\n      ... on SubmitJoinOrganizationRequestError {\n        id\n      }\n    }\n    inquirySent\n    userAccount {\n      emailAddress\n      profile {\n        firstName\n        lastName\n        id\n      }\n      ...InquiryContextProvider_userAccount\n      id\n    }\n  }\n}\n\nfragment InquiryContextProvider_userAccount on UserAccount {\n  paidProjects(first: 1, filter: {source: GENERAL_INQUIRY}) {\n    count\n  }\n  createdJobPostings(first: 1, filter: {status: [APPROVED, ARCHIVED, PENDING, PENDING_REVISION, EXPIRED]}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "31cabefc6afba1c72f0be2b0a59b6a9c";

export default node;
