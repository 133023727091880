import { SelectMenuAddIcon } from './SelectMenuAddIcon.js';
import { SelectMenuDialog } from './SelectMenuDialog.js';
import { SelectMenuHeader } from './SelectMenuHeader.js';
import { SelectMenuIcon } from './SelectMenuIcon.js';
import { SelectMenuItem } from './SelectMenuItem.js';
import { SelectMenuList } from './SelectMenuList.js';
import { SelectMenuPlaceholder } from './SelectMenuPlaceholder.js';
import { type ComponentPropsWithRef } from 'react';

type SelectMenuProps = ComponentPropsWithRef<'div'>;

const SelectMenu = ({ children, ...props }: SelectMenuProps) => (
  <div {...props}>{children}</div>
);

SelectMenu.AddIcon = SelectMenuAddIcon;
SelectMenu.Dialog = SelectMenuDialog;
SelectMenu.Header = SelectMenuHeader;
SelectMenu.Icon = SelectMenuIcon;
SelectMenu.Placeholder = SelectMenuPlaceholder;
SelectMenu.Item = SelectMenuItem;
SelectMenu.List = SelectMenuList;

export { SelectMenu };
