import { styled } from '@/stitches/index.js';

export const CodeInputContainer = styled('div', {
  '&:focus-within > input': {
    borderColor: '$brandYellow',
  },
  display: 'flex',
  flexFlow: 'row nowrap',
  input: {
    '&::placeholder': {
      color: '$gray30',
    },
    '&:first-of-type': {
      borderLeftWidth: '$1',
      borderRadius: '$16 0 0 $16',
      paddingLeft: '$12',
      width: '$54',
    },
    '&:last-of-type': {
      borderRadius: '0 $16 $16 0',
      borderRightWidth: '$1',
      paddingRight: '$12',
      width: '$54',
    },
    background: '$white',
    borderColor: '$gray40',
    borderRadius: '0',
    borderStyle: 'solid',
    borderWidth: '$1 0',
    color: '$gray70',
    fontSize: '$24',
    fontWeight: '600',
    padding: '$16 0',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  margin: '$16 0 0',
  width: '$296',
});
