import { InquiryFormV2 } from './InquiryFormV2.js';
import { InquiryFormV2Data } from './types.js';
import { Modal } from '@/components/ModalV2/Modal.js';
import { PropertyLibrary } from '@/services/analytics/propertyLibrary.js';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

type Props = {
  readonly defaultValues: InquiryFormV2Data;
  readonly form: UseFormReturn<InquiryFormV2Data>;
  readonly hasActiveJobPostings: boolean;
  readonly isOpen: boolean;
  readonly isSendingInquiry: boolean;
  readonly onClose: (cta: PropertyLibrary['cta'] | null) => void;
  readonly onSubmit: (data: InquiryFormV2Data, onSuccess: VoidFunction) => void;
};

export const InquiryModalV2 = ({
  defaultValues,
  form,
  hasActiveJobPostings,
  isOpen,
  isSendingInquiry,
  onClose,
  onSubmit,
}: Props) => {
  const [step, setStep] = useState<0 | 1>(0);

  const handleClose = useCallback(
    (cta: PropertyLibrary['cta'] | null) => {
      form.reset(defaultValues);
      onClose(cta);
      setStep(0);
    },
    [defaultValues, form, onClose],
  );

  return (
    <Modal.Root open={isOpen}>
      <Modal.Content
        progressPercent={step === 0 ? 50 : 100}
        variant="modalNew"
      >
        <Modal.Close onClick={() => handleClose('close')} />
        <Modal.Body>
          <InquiryFormV2
            form={form}
            hasActiveJobPostings={hasActiveJobPostings}
            isSubmitting={isSendingInquiry}
            onClose={() => handleClose('secondary')}
            onSubmit={(data) => onSubmit(data, () => setStep(0))}
            setStep={setStep}
            step={step}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
