import { styled } from '@/stitches/index.js';
import { type ContraImageProps } from '@/types/contraImage.js';
import NextImage from 'next/legacy/image';

export const AvatarContainer = styled('div', {
  '& img': {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  userSelect: 'none',
  variants: {
    rounded: {
      true: {
        '& img': {
          borderRadius: '100%',
        },
      },
    },
  },
});

export type AvatarProps = ContraImageProps & {
  readonly rounded?: boolean;
};

export const Avatar = ({
  className,
  height,
  layout,
  rounded = false,
  src,
  uid,
  width,
  ...imageProps
}: AvatarProps) => {
  const resolvedSource = uid ? `/${uid}` : src;

  return resolvedSource ? (
    <AvatarContainer
      // eslint-disable-next-line react/forbid-component-props
      className={className}
      rounded={rounded}
    >
      <NextImage
        height={height}
        layout={layout || !height || !width ? 'fill' : undefined}
        src={resolvedSource}
        width={width}
        {...imageProps}
      />
    </AvatarContainer>
  ) : null;
};
