import { styled } from '@/stitches/index.js';

const Container = styled('div', {
  alignItems: 'center',
  backgroundColor: '$gray10',
  borderRadius: '50%',
  color: '$brandBlack',
  display: 'flex',
  height: '$24',
  justifyContent: 'center',
  textAlign: 'center',
  width: '$24',
});

export const SelectMenuAddIcon = () => {
  return (
    <Container>
      <svg
        focusable={false}
        height="100%"
        role="img"
        viewBox="0 0 24 24"
        width="100%"
      >
        <line
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          x1="9"
          x2="15"
          y1="50%"
          y2="50%"
        />
        <line
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          x1="50%"
          x2="50%"
          y1="9"
          y2="15"
        />
      </svg>
    </Container>
  );
};
