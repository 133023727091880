import { styled } from '@/stitches/index.js';

const SelectMenuIcon = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: '$24',
  marginRight: '$12',
  width: '$24',
});

SelectMenuIcon.displayName = 'SelectMenu.LinkIcon';

export { SelectMenuIcon };
