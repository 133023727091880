import { styled } from '@/stitches/index.js';

const Span = styled('span', {
  color: '$gray50',
  fontSize: '$16',
});

export const SelectMenuPlaceholder = () => {
  return <Span>-Select-</Span>;
};
