import {
  type ProjectDurationSelectOption,
  type ProjectDurationSelectProps,
} from './types.js';
import { SelectMenu } from '@/components/SelectMenu/SelectMenu.js';
import { TextField } from '@/components/TextField/TextField.js';
import { styled } from '@/stitches/index.js';
import { useSelect } from 'downshift';

export const PROJECT_DURATION_OPTIONS: ProjectDurationSelectOption[] = [
  {
    id: 'SHORT',
    max: 7,
    min: 1,
    title: 'Less than 1 week',
  },
  {
    id: 'WEEK',
    max: 28,
    min: 8,
    title: '1-4 weeks',
  },
  {
    id: 'MONTH',
    max: 90,
    min: 31,
    title: '1-3 months',
  },
  {
    id: 'QUARTER',
    max: 180,
    min: 91,
    title: '3-6 months',
  },
  {
    id: 'LONG',
    max: 360,
    min: 181,
    title: `Longer than 6 months`,
  },
];

const StyledTextField = styled(TextField, {
  '&&& > button > span:first-child': {
    margin: '0 0 $6 $16',
  },
  boxShadow: '$basic',
});

export const ProjectDurationSelect = ({
  disabled = false,
  hasError,
  initialSelectedItem,
  onSelection,
  variant = 'default',
}: ProjectDurationSelectProps) => {
  const {
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    selectedItem: selectedProjectDuration,
  } = useSelect({
    initialSelectedItem,
    items: PROJECT_DURATION_OPTIONS,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!disabled && selectedItem?.id) {
        onSelection(selectedItem);
      }
    },
  });

  return (
    <StyledTextField
      hasError={hasError}
      variant={variant}
    >
      <TextField.Label {...getLabelProps()}>Project Duration</TextField.Label>
      <TextField.InputGroup
        aria-disabled={disabled}
        as="button"
        type="button"
        variant={variant}
        {...getToggleButtonProps()}
      >
        <TextField.SelectionText
          isDisabled={disabled}
          isPlaceholder={!selectedProjectDuration}
        >
          {selectedProjectDuration ? selectedProjectDuration.title : '-Select-'}
        </TextField.SelectionText>

        {disabled ? (
          <TextField.Disabled />
        ) : (
          <TextField.Chevron isOpen={isOpen} />
        )}
      </TextField.InputGroup>
      {disabled ? null : (
        <TextField.Menu>
          <SelectMenu>
            <SelectMenu.Dialog {...getMenuProps({})}>
              {isOpen && (
                <SelectMenu.List>
                  {PROJECT_DURATION_OPTIONS.map((item, index) => {
                    return (
                      <SelectMenu.Item
                        isHighlighted={highlightedIndex === index}
                        key={item.id}
                        {...getItemProps({ index, item })}
                      >
                        {item.title}
                      </SelectMenu.Item>
                    );
                  })}
                </SelectMenu.List>
              )}
            </SelectMenu.Dialog>
          </SelectMenu>
        </TextField.Menu>
      )}
    </StyledTextField>
  );
};
