import { styled } from '@/stitches/index.js';
import { CheckIcon } from '@contra/icons/icons';
import { type ComponentPropsWithoutRef, forwardRef, type Ref } from 'react';

const ListItem = styled('li', {
  '&:hover': {
    backgroundColor: '$gray10',
  },
  alignItems: 'center',
  backgroundColor: '$white',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '$16',
  justifyContent: 'space-between',
  lineHeight: '1.5',
  padding: '$12 $16',
  variants: {
    isAlone: {
      true: {
        bottom: '-$48',
      },
    },
    isHighlighted: {
      true: {
        backgroundColor: '$gray10',
      },
    },
    isPinned: {
      true: {
        borderRadius: '0 0 $10 $10',
        borderTop: '$1 solid $gray20',
        bottom: '0',
        position: 'absolute',
        width: '100%',
        zIndex: '1',
      },
    },
    isSelected: {
      true: {
        backgroundColor: '$gray10',
      },
    },
  },
});

const Child = styled('div', {
  display: 'flex',
  flex: '1',
});

const Checkmark = styled('div', {
  alignItems: 'center',
  display: 'flex',
  marginLeft: '$16',
});

type ListItemProps = ComponentPropsWithoutRef<typeof ListItem> & {
  readonly isAlone?: boolean;
  readonly isHighlighted?: boolean;
  readonly isPinned?: boolean;
  readonly isSelected?: boolean;
};

type SelectMenuItemProps = ListItemProps & {
  readonly children: React.ReactNode;
};

const SelectMenuItem = forwardRef(
  (
    { children, isSelected, ...props }: SelectMenuItemProps,
    ref: Ref<HTMLLIElement>,
  ) => (
    <ListItem
      ref={ref}
      {...props}
      isSelected={isSelected}
    >
      <Child>{children}</Child>
      {isSelected && (
        <Checkmark>
          <CheckIcon size={24} />
        </Checkmark>
      )}
    </ListItem>
  ),
);

SelectMenuItem.displayName = 'SelectMenu.Item';

export { SelectMenuItem };
