import { ContraRocketIcon } from '@/components/Icons/ContraRocketIcon.js';
import { Modal } from '@/components/Modal/Modal.js';
import { PlatformButton } from '@/components/PlatformButton/PlatformButton.js';
import { useIPUtmParameters } from '@/hooks/useIPUtmParameters.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import { type PropertyLibrary } from '@/services/analytics/propertyLibrary.js';
import { getContraUrl } from '@/utilities/getContraUrl.js';
import { useMemo } from 'react';

const ICON_SIZE = 54;

const BUTTON_MIN_WIDTH_STYLES = {
  '@bp1': {
    minWidth: `$200`,
  },
};

export const CompleteSignUpModal = ({
  isOpen,
  onClose,
}: {
  readonly isOpen: boolean;
  readonly onClose: (cta: PropertyLibrary['cta'] | null) => void;
}) => {
  const userProfile = useUserProfile();
  const ipUtmParameters = useIPUtmParameters('ip_complete_signup_modal');
  const signUpHref = useMemo(() => {
    const parameters = new URLSearchParams({
      redirectTo: `${userProfile?.displayUsername}?startInquiry=true&redirectToPortfolio=true&justSignedUp=true`,
      userType: 'client',
      ...ipUtmParameters,
    });

    return new URL(getContraUrl('sign-up?') + parameters.toString()).toString();
  }, [ipUtmParameters, userProfile?.displayUsername]);

  return (
    <Modal.Root open={isOpen}>
      <Modal.Content
        css={{
          '@bp3': { paddingTop: '$48  ' },
        }}
      >
        <Modal.Body>
          <ContraRocketIcon
            height={ICON_SIZE}
            width={ICON_SIZE}
          />
          <Modal.Title>Hire and Manage Independents</Modal.Title>
          <Modal.Description>
            Post opportunities, explore Independents, and manage all of your
            projects in one place.
          </Modal.Description>
          <Modal.Buttons>
            <PlatformButton
              as="a"
              css={BUTTON_MIN_WIDTH_STYLES}
              href={signUpHref}
              onClick={() => onClose('primary')}
              variant="primary"
            >
              Complete Sign Up
            </PlatformButton>

            <PlatformButton
              css={BUTTON_MIN_WIDTH_STYLES}
              onClick={() => onClose('secondary')}
              variant="secondary"
            >
              Maybe Later
            </PlatformButton>
          </Modal.Buttons>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
