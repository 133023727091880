export const ContraRocketIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="54"
    viewBox="0 0 55 54"
    width="55"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_850_17492)">
      <g filter="url(#filter0_f_850_17492)">
        <ellipse
          cx="16.1642"
          cy="38.25"
          fill="url(#paint0_radial_850_17492)"
          rx="10.1194"
          ry="11.4352"
          transform="rotate(45 16.1642 38.25)"
        />
      </g>
      <path
        clipRule="evenodd"
        d="M14.7187 38.1704L15.8904 39.3421C16.7644 40.2161 17.1234 41.4099 16.9677 42.5467C17.7617 44.0432 17.365 46.1329 15.8853 47.6127C14.6142 48.8837 11.6913 50.1509 9.83787 49.9894C9.29085 49.9417 9.06661 49.3601 9.18882 48.8247C9.33696 48.1759 9.40838 47.5419 9.45319 46.933C7.96875 47.0216 6.3543 47.3057 5.12734 48.0115C4.66373 48.2781 4.04992 48.3454 3.76499 47.8928C2.44768 45.8002 4.15797 41.0155 5.55641 39.6171L7.00368 38.1698C9.13428 36.0392 12.5881 36.0398 14.7187 38.1704Z"
        fill="url(#paint1_radial_850_17492)"
        fillRule="evenodd"
      />
      <g
        filter="url(#filter1_f_850_17492)"
        opacity="0.6"
      >
        <path
          d="M13.9002 38.3052C17.8878 41.2959 17.2891 47.25 11.6641 48.375C11.6641 48.375 13.8904 46.0337 13.9141 42.75C13.9378 39.4664 13.9002 38.3052 13.9002 38.3052Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_i_850_17492)">
        <path
          d="M12.0736 32.7518L21.6195 42.2978L20.8241 43.0933C19.506 44.4113 17.3691 44.4113 16.0511 43.0933L11.2781 38.3203C9.96009 37.0023 9.96009 34.8653 11.2781 33.5473L12.0736 32.7518Z"
          fill="url(#paint2_linear_850_17492)"
        />
      </g>
      <g filter="url(#filter3_iii_850_17492)">
        <path
          d="M33.5518 36.7293L31.3817 46.5321C31.0916 47.8424 29.8008 48.4306 28.7592 47.5844C27.969 46.9425 26.9286 46.016 25.5968 44.6842C24.265 43.3524 23.3385 42.3121 22.6966 41.5219C21.8504 40.4802 22.4387 39.1894 23.749 38.8994L33.5518 36.7293Z"
          fill="url(#paint3_angular_850_17492)"
        />
      </g>
      <g filter="url(#filter4_iii_850_17492)">
        <path
          d="M17.642 20.8194L15.4719 30.6222C15.1818 31.9325 13.891 32.5207 12.8494 31.6745C12.0592 31.0326 11.0189 30.1061 9.68704 28.7743C8.35522 27.4425 7.42872 26.4021 6.78683 25.612C5.94066 24.5703 6.52887 23.2795 7.83916 22.9894L17.642 20.8194Z"
          fill="url(#paint4_angular_850_17492)"
        />
      </g>
      <g filter="url(#filter5_ii_850_17492)">
        <path
          d="M41.5068 3.31841L38.875 3.84477C32.8105 5.05766 27.2408 8.0385 22.8677 12.4116C19.9432 15.3361 17.507 18.792 16.6333 22.8345C15.6952 27.1746 15.3114 32.8077 18.4375 35.9337C21.5635 39.0598 27.1966 38.676 31.5367 37.7379C35.5792 36.8642 39.035 34.428 41.9595 31.5035C46.3327 27.1304 49.3135 21.5606 50.5264 15.4962L51.0528 12.8643L51.0528 3.31841L41.5068 3.31841Z"
          fill="url(#paint5_radial_850_17492)"
        />
      </g>
      <g filter="url(#filter6_iii_850_17492)">
        <path
          d="M26.0665 25.6064C27.6867 25.2325 29.1387 26.6845 28.7648 28.3047L25.3578 43.0686C25.0594 44.3616 23.7682 44.9783 22.6931 44.2006C21.2965 43.1902 19.1433 41.4126 16.0509 38.3203C12.9586 35.2279 11.181 33.0747 10.1706 31.6781C9.39288 30.603 10.0096 29.3118 11.3026 29.0134L26.0665 25.6064Z"
          fill="url(#paint6_angular_850_17492)"
        />
      </g>
      <g filter="url(#filter7_i_850_17492)">
        <path
          d="M51.8482 2.52297C52.5108 3.18561 52.7984 4.13558 52.6147 5.0545L51.0527 12.8644C51.0527 12.8644 48.6662 12.0689 45.4842 8.88693C42.3022 5.70495 41.5067 3.31847 41.5067 3.31847L49.3166 1.75649C50.2356 1.5727 51.1855 1.86033 51.8482 2.52297Z"
          fill="url(#paint7_angular_850_17492)"
        />
      </g>
      <g filter="url(#filter8_ii_850_17492)">
        <circle
          cx="36.7338"
          cy="17.6374"
          fill="url(#paint8_linear_850_17492)"
          r="4.5"
          transform="rotate(45 36.7338 17.6374)"
        />
      </g>
      <g filter="url(#filter9_i_850_17492)">
        <circle
          cx="37.5294"
          cy="16.8418"
          fill="url(#paint9_radial_850_17492)"
          r="6.75"
          transform="rotate(45 37.5294 16.8418)"
        />
      </g>
      <circle
        fill="url(#paint10_radial_850_17492)"
        r="4.5"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 37.5292 16.8419)"
      />
      <g filter="url(#filter10_f_850_17492)">
        <path
          d="M7.91418 40.5C6.077 41.3384 3.83543 43.83 5.49453 46.5L7.91418 40.5Z"
          fill="#FFD179"
        />
      </g>
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="30.595"
        id="filter0_f_850_17492"
        width="30.595"
        x="0.866699"
        y="22.9525"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_850_17492"
          stdDeviation="2.25"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="14.5698"
        id="filter1_f_850_17492"
        width="9.30139"
        x="9.41406"
        y="36.0552"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_850_17492"
          stdDeviation="1.125"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="12.455"
        id="filter2_i_850_17492"
        width="12.455"
        x="10.2896"
        y="31.6268"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="1.125"
          dy="-1.125"
        />
        <feGaussianBlur stdDeviation="1.125" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.110208 0 0 0 0 0.268237 0 0 0 0 0.575 0 0 0 0.6 0"
        />
        <feBlend
          in2="shape"
          mode="normal"
          result="effect1_innerShadow_850_17492"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="13.5002"
        id="filter3_iii_850_17492"
        width="13.5001"
        x="21.1766"
        y="35.6042"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="-1.125"
          dy="1.125"
        />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.720833 0 0 0 0 0.843394 0 0 0 0.4 0"
        />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_850_17492"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="1.125"
          dy="-1.125"
        />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.6 0"
        />
        <feBlend
          in2="effect1_innerShadow_850_17492"
          mode="normal"
          result="effect2_innerShadow_850_17492"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345833 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          in2="effect2_innerShadow_850_17492"
          mode="normal"
          result="effect3_innerShadow_850_17492"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="13.5002"
        id="filter4_iii_850_17492"
        width="13.5001"
        x="5.26685"
        y="19.6943"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="-1.125"
          dy="1.125"
        />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.720833 0 0 0 0 0.843394 0 0 0 0.4 0"
        />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_850_17492"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="1.125"
          dy="-1.125"
        />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.6 0"
        />
        <feBlend
          in2="effect1_innerShadow_850_17492"
          mode="normal"
          result="effect2_innerShadow_850_17492"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345833 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          in2="effect2_innerShadow_850_17492"
          mode="normal"
          result="effect3_innerShadow_850_17492"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="37.3402"
        id="filter5_ii_850_17492"
        width="37.3401"
        x="14.8376"
        y="2.19336"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="-1.125"
          dy="1.125"
        />
        <feGaussianBlur stdDeviation="1.125" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.968627 0 0 0 0 0.172549 0 0 0 0 0.32549 0 0 0 1 0"
        />
        <feBlend
          in2="shape"
          mode="darken"
          result="effect1_innerShadow_850_17492"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="1.125"
          dy="-1.125"
        />
        <feGaussianBlur stdDeviation="1.6875" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.886275 0 0 0 0 0.262745 0 0 0 0 0.0784314 0 0 0 1 0"
        />
        <feBlend
          in2="effect1_innerShadow_850_17492"
          mode="multiply"
          result="effect2_innerShadow_850_17492"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="21.2465"
        id="filter6_iii_850_17492"
        width="21.2465"
        x="8.70276"
        y="24.422"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="-1.125"
          dy="1.125"
        />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.720833 0 0 0 0 0.843394 0 0 0 0.4 0"
        />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_850_17492"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="1.125"
          dy="-1.125"
        />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.6 0"
        />
        <feBlend
          in2="effect1_innerShadow_850_17492"
          mode="normal"
          result="effect2_innerShadow_850_17492"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345833 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          in2="effect2_innerShadow_850_17492"
          mode="normal"
          result="effect3_innerShadow_850_17492"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="12.2874"
        id="filter7_i_850_17492"
        width="12.2874"
        x="40.3817"
        y="1.70203"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="-1.125"
          dy="1.125"
        />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.720833 0 0 0 0 0.843394 0 0 0 0.4 0"
        />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_850_17492"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="11.25"
        id="filter8_ii_850_17492"
        width="11.25"
        x="31.1088"
        y="12.0125"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="-1.125"
          dy="1.125"
        />
        <feGaussianBlur stdDeviation="0.5625" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.944167 0 0 0 0 0.720833 0 0 0 0 1 0 0 0 0.4 0"
        />
        <feBlend
          in2="shape"
          mode="screen"
          result="effect1_innerShadow_850_17492"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="1.125"
          dy="-1.125"
        />
        <feGaussianBlur stdDeviation="1.125" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.110208 0 0 0 0 0.268237 0 0 0 0 0.575 0 0 0 0.6 0"
        />
        <feBlend
          in2="effect1_innerShadow_850_17492"
          mode="normal"
          result="effect2_innerShadow_850_17492"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="14.625"
        id="filter9_i_850_17492"
        width="14.625"
        x="30.7794"
        y="8.9668"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx="1.125"
          dy="-1.125"
        />
        <feGaussianBlur stdDeviation="1.125" />
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.110208 0 0 0 0 0.268237 0 0 0 0 0.575 0 0 0 0.6 0"
        />
        <feBlend
          in2="shape"
          mode="normal"
          result="effect1_innerShadow_850_17492"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="10.5"
        id="filter10_f_850_17492"
        width="7.5"
        x="2.66418"
        y="38.25"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          mode="normal"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_850_17492"
          stdDeviation="1.125"
        />
      </filter>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(19.1838 45.6387) rotate(-87.4511) scale(15.9339 18.6861)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_850_17492"
        r="1"
      >
        <stop
          offset="0.0940858"
          stopColor="#FFB15E"
        />
        <stop
          offset="0.447283"
          stopColor="#FF855F"
        />
        <stop
          offset="0.968125"
          stopColor="#FF4033"
        />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(11.6641 42.7501) rotate(141.34) scale(7.20352 8.44107)"
        gradientUnits="userSpaceOnUse"
        id="paint1_radial_850_17492"
        r="1"
      >
        <stop
          offset="0.0940858"
          stopColor="#FFCB13"
        />
        <stop
          offset="1"
          stopColor="#FF1000"
        />
      </radialGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_850_17492"
        x1="12.0736"
        x2="20.8241"
        y1="32.7518"
        y2="41.5023"
      >
        <stop offset="0.119792" />
        <stop
          offset="0.458333"
          stopColor="#444274"
        />
        <stop
          offset="0.764038"
          stopColor="#3948F8"
        />
        <stop offset="1" />
      </linearGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(35.4235 34.8575) rotate(-135) scale(8.775 8.60294)"
        gradientUnits="userSpaceOnUse"
        id="paint3_angular_850_17492"
        r="1"
      >
        <stop
          offset="0.685913"
          stopColor="#F75900"
        />
        <stop
          offset="0.748349"
          stopColor="#FF9EB3"
        />
        <stop
          offset="0.813412"
          stopColor="#F2002C"
        />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(19.5137 18.9476) rotate(-135) scale(8.775 8.60294)"
        gradientUnits="userSpaceOnUse"
        id="paint4_angular_850_17492"
        r="1"
      >
        <stop
          offset="0.685913"
          stopColor="#F75900"
        />
        <stop
          offset="0.748349"
          stopColor="#FF9EB3"
        />
        <stop
          offset="0.813412"
          stopColor="#F2002C"
        />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(40.7113 13.6598) rotate(135) scale(33.75 15.0577)"
        gradientUnits="userSpaceOnUse"
        id="paint5_radial_850_17492"
        r="1"
      >
        <stop
          offset="0.212903"
          stopColor="#FFEAEA"
        />
        <stop
          offset="0.718121"
          stopColor="#FDFBFF"
        />
        <stop
          offset="1"
          stopColor="#FFD179"
        />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(32.7563 21.6149) rotate(-135) scale(14.625)"
        gradientUnits="userSpaceOnUse"
        id="paint6_angular_850_17492"
        r="1"
      >
        <stop
          offset="0.685913"
          stopColor="#F75900"
        />
        <stop
          offset="0.748349"
          stopColor="#FF9EB3"
        />
        <stop
          offset="0.813412"
          stopColor="#F2002C"
        />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(53.2094 1.16169) rotate(-135) scale(9.75 6.25668)"
        gradientUnits="userSpaceOnUse"
        id="paint7_angular_850_17492"
        r="1"
      >
        <stop
          offset="0.675108"
          stopColor="#FCB690"
        />
        <stop
          offset="0.709256"
          stopColor="#F75900"
        />
        <stop
          offset="0.748349"
          stopColor="#FF9EB3"
        />
        <stop
          offset="0.788499"
          stopColor="#F51D47"
        />
        <stop
          offset="0.826216"
          stopColor="#FE7E94"
        />
      </radialGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint8_linear_850_17492"
        x1="36.7338"
        x2="36.7338"
        y1="13.1374"
        y2="22.1374"
      >
        <stop
          offset="0.252473"
          stopColor="#67CCEC"
        />
        <stop
          offset="0.764038"
          stopColor="#211ED7"
        />
        <stop
          offset="1"
          stopColor="#00BBF7"
        />
      </linearGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(37.5294 16.8418) rotate(90) scale(6.75)"
        gradientUnits="userSpaceOnUse"
        id="paint9_radial_850_17492"
        r="1"
      >
        <stop offset="0.671245" />
        <stop
          offset="0.796247"
          stopColor="#3548DC"
        />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(4.5303 2.89251) rotate(90) scale(6.36396)"
        gradientUnits="userSpaceOnUse"
        id="paint10_radial_850_17492"
        r="1"
      >
        <stop stopColor="#76A8FF" />
        <stop
          offset="1"
          stopColor="#002EFF"
        />
      </radialGradient>
      <clipPath id="clip0_850_17492">
        <rect
          fill="white"
          height="54"
          transform="translate(0.414062)"
          width="54"
        />
      </clipPath>
    </defs>
  </svg>
);
