import { type SVGProps } from 'react';

export const ArmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="1em"
    role="img"
    viewBox="0 0 16 16"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#arm_svg__a)">
      <mask
        height={17}
        id="arm_svg__c"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
        width={15}
        x={1}
        y={0}
      >
        <path
          d="m3.001 15.51-.871.108a.954.954 0 0 1-1.063-1.081l.18-1.26c.058-.401.087-.806.087-1.212 0-.922.148-1.838.44-2.712l1.492-4.478c.046-.136.069-.279.069-.422v-1.34c0-.289.093-.57.267-.8L4.722.818A1.334 1.334 0 0 1 6.285.38l1.802.72a.667.667 0 0 1 .385.83l-.134.405.138.138a.667.667 0 0 1 .195.472v.094a.523.523 0 0 1-.254.449l-.089.053a1.77 1.77 0 0 0-.716.82c-.172.402-.53.693-.96.779l-.446.09a.667.667 0 0 0-.537.653v4.123l-.166 1.334h.333l.889-1.28c1.69-2.434 5.194-2.697 7.23-.542l1.174 1.244c.351.371.546.863.546 1.374v.307c0 .571-.244 1.116-.671 1.495l-.624.555a6.003 6.003 0 0 1-3.989 1.517H8.076c-.49 0-.98-.06-1.456-.18l-.567-.141a6.006 6.006 0 0 0-1.456-.18H3Z"
          fill="url(#arm_svg__b)"
        />
      </mask>
      <g mask="url(#arm_svg__c)">
        <g filter="url(#arm_svg__d)">
          <path
            d="m3.001 15.51-.871.108a.954.954 0 0 1-1.063-1.081l.18-1.26c.058-.401.087-.806.087-1.212 0-.922.148-1.838.44-2.712l1.492-4.478c.046-.136.069-.279.069-.422v-1.34c0-.289.093-.57.267-.8L4.722.818A1.334 1.334 0 0 1 6.285.38l1.802.72a.667.667 0 0 1 .385.83l-.134.405.138.138a.667.667 0 0 1 .195.472v.094a.523.523 0 0 1-.254.449l-.089.053a1.77 1.77 0 0 0-.716.82c-.172.402-.53.693-.96.779l-.446.09a.667.667 0 0 0-.537.653v4.123l-.166 1.334h.333l.889-1.28c1.69-2.434 5.194-2.697 7.23-.542l1.174 1.244c.351.371.546.863.546 1.374v.307c0 .571-.244 1.116-.671 1.495l-.624.555a6.003 6.003 0 0 1-3.989 1.517H8.076c-.49 0-.98-.06-1.456-.18l-.567-.141a6.006 6.006 0 0 0-1.456-.18H3Z"
            fill="url(#arm_svg__e)"
          />
        </g>
        <g
          filter="url(#arm_svg__f)"
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M3.336 4.836c1.668.5 4.503-.166 6.004-1.834l-3.002 7.671s-.5-.834-1-2.501c-.501-1.668-2.002-3.336-2.002-3.336Z"
            fill="url(#arm_svg__g)"
          />
        </g>
        <g
          filter="url(#arm_svg__h)"
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m6.002 3.202-1.334-.534 1.334-.467L5.335.867l1.334.667 1.334.134-1 .533h-.667l.333.667 1.78-.667-1.113 1H6.002Z"
            fill="url(#arm_svg__i)"
          />
        </g>
        <g
          filter="url(#arm_svg__j)"
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M4 5.003c1.334.267 3.002.334 4.002-.333l-2 2.668s-.334-.333-.668-1C5.001 5.67 4 5.003 4 5.003Z"
            fill="url(#arm_svg__k)"
          />
        </g>
        <g
          filter="url(#arm_svg__l)"
          opacity={0.5}
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M.378 6.421c.974-.949 1.591-4.349 3.625-4.753 0 0 .5 2.002.333 3.002-.167 1-1.334 2.001-1.334 2.001s-.03-.493-.77-.595C1.494 5.974.378 6.42.378 6.42Z"
            fill="url(#arm_svg__m)"
          />
        </g>
        <g
          filter="url(#arm_svg__n)"
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M11.617 5.192 4.56 13.286s.088-1.38.717-3.93c.395-3.686-.061.307 6.341-4.164Z"
            fill="url(#arm_svg__o)"
          />
        </g>
        <g
          filter="url(#arm_svg__p)"
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M17.008 10.34c-.667-2.001-.333 2.668-5.003 4.003-4.67 1.334-6.337 1-6.337 1l7.338 2.335s4.67-5.337 4.002-7.338Z"
            fill="url(#arm_svg__q)"
          />
        </g>
        <g
          filter="url(#arm_svg__r)"
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M4.668 12.674c1.334.267 1.334-2.668 1.334-2.668v-.667l-1.334 3.335Z"
            fill="url(#arm_svg__s)"
          />
        </g>
        <g
          filter="url(#arm_svg__t)"
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M6.004 12.007v.668s-.667-.668-.334-1.668l.334 1Z"
            fill="url(#arm_svg__u)"
          />
        </g>
        <g
          filter="url(#arm_svg__v)"
          opacity={0.75}
          style={{
            mixBlendMode: 'screen',
          }}
        >
          <path
            d="m12.272 9.552 1.76 1.896a.667.667 0 0 1-.224 1.066l-3.272 1.41a.667.667 0 0 1-.405.04l-3.147-.678a.667.667 0 0 1-.407-1.034l1.83-2.627a.667.667 0 0 1 .547-.286h2.83c.185 0 .362.077.488.213Z"
            fill="url(#arm_svg__w)"
          />
        </g>
      </g>
      <g
        filter="url(#arm_svg__x)"
        opacity={0.75}
        style={{
          mixBlendMode: 'screen',
        }}
      >
        <path
          d="m4.64 9.541-.231-1.036c-.136-.613-.967-.715-1.248-.154l-.773 1.545a.667.667 0 0 0-.067.232l-.248 2.48c-.039.393.27.734.664.734h.707c.323 0 .6-.231.657-.548l.543-2.989a.667.667 0 0 0-.005-.264Z"
          fill="url(#arm_svg__y)"
        />
      </g>
      <g
        filter="url(#arm_svg__z)"
        style={{
          mixBlendMode: 'screen',
        }}
      >
        <path
          d="m4.148 13.384-2.094.465a.404.404 0 0 0 .154.792l3-.5a.651.651 0 1 0-.568-1.102l-.165.165a.668.668 0 0 1-.327.18Z"
          fill="url(#arm_svg__A)"
        />
      </g>
      <g
        filter="url(#arm_svg__B)"
        strokeWidth={0.167}
        style={{
          mixBlendMode: 'multiply',
        }}
      >
        <path
          d="M8.506 2.501a.472.472 0 0 0-.667 0l-.032.032a1.032 1.032 0 0 1-.73.302h-.406m-.5 0-.667-.167m.667.167V2.56m0 .275h.5m-.5-1-.334-.501m.334.5v.258m0-.258.167.042m.5.125.167.334M6.838 2h.334l.833-.333M6.838 2l-.5-.125m-.167.684.5.275m-.5-.275v-.468m0 0 .167-.216"
          stroke="url(#arm_svg__C)"
        />
        <path
          d="M8.506 2.501a.472.472 0 0 0-.667 0l-.032.032a1.032 1.032 0 0 1-.73.302h-.406m-.5 0-.667-.167m.667.167V2.56m0 .275h.5m-.5-1-.334-.501m.334.5v.258m0-.258.167.042m.5.125.167.334M6.838 2h.334l.833-.333M6.838 2l-.5-.125m-.167.684.5.275m-.5-.275v-.468m0 0 .167-.216"
          stroke="url(#arm_svg__D)"
        />
      </g>
      <g filter="url(#arm_svg__E)">
        <path
          d="m13.914.666.116.585c.106.53.52.943 1.049 1.049l.585.116v.5l-.585.116c-.53.106-.943.52-1.049 1.049l-.116.585h-.5l-.116-.585a1.334 1.334 0 0 0-1.049-1.049l-.585-.116v-.5l.585-.116c.53-.106.944-.52 1.049-1.049l.116-.585h.5Z"
          fill="url(#arm_svg__F)"
        />
      </g>
      <g filter="url(#arm_svg__G)">
        <path
          d="m11.207 3.667.097.488c.088.44.432.786.874.873l.487.097v.417l-.487.097a1.112 1.112 0 0 0-.874.874L11.207 7h-.417l-.097-.487a1.112 1.112 0 0 0-.873-.874l-.488-.097v-.417l.488-.097c.44-.087.786-.432.873-.873l.097-.488h.417Z"
          fill="url(#arm_svg__H)"
        />
      </g>
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={16.393}
        id="arm_svg__d"
        width={15.284}
        x={0.725}
        y={-0.049}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.334}
          dy={0.334}
        />
        <feGaussianBlur stdDeviation={0.167} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0.943902 0 0 0 0.7 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_6032_140187"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.334}
          dy={-0.334}
        />
        <feGaussianBlur stdDeviation={0.167} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.333333 0 0 0 0 0.227451 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_6032_140187"
          result="effect2_innerShadow_6032_140187"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.25} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.666667 0 0 0 0 0.16 0 0 0 0 0 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_6032_140187"
          result="effect3_innerShadow_6032_140187"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={9.672}
        id="arm_svg__f"
        width={8.005}
        x={2.335}
        y={2.001}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.5}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={3.002}
        id="arm_svg__h"
        width={4.448}
        x={4.334}
        y={0.534}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.167}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={3.335}
        id="arm_svg__j"
        width={4.671}
        x={3.666}
        y={4.336}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.167}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={5.67}
        id="arm_svg__l"
        width={4.659}
        x={0.045}
        y={1.334}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.167}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={10.095}
        id="arm_svg__n"
        width={9.06}
        x={3.558}
        y={4.192}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.5}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={9.83}
        id="arm_svg__p"
        width={13.408}
        x={4.667}
        y={8.848}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.5}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={4.02}
        id="arm_svg__r"
        width={2.003}
        x={4.334}
        y={9.005}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.167}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={2.335}
        id="arm_svg__t"
        width={1.093}
        x={5.245}
        y={10.673}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.167}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={8.642}
        id="arm_svg__v"
        width={11.756}
        x={4.456}
        y={7.338}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={1.001}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={9.362}
        id="arm_svg__x"
        width={6.588}
        x={0.069}
        y={5.981}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={1.001}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={4.467}
        id="arm_svg__z"
        width={6.684}
        x={0.404}
        y={11.514}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.667}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={1.963}
        id="arm_svg__B"
        width={3.416}
        x={5.318}
        y={1.121}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          result="effect1_foregroundBlur_6032_140187"
          stdDeviation={0.083}
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={4.667}
        id="arm_svg__E"
        width={4.667}
        x={11.331}
        y={0.332}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.334}
          dy={0.334}
        />
        <feGaussianBlur stdDeviation={0.167} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0.943902 0 0 0 0.3 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_6032_140187"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.334}
          dy={-0.334}
        />
        <feGaussianBlur stdDeviation={0.167} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.494118 0 0 0 0 0.0392157 0 0 0 0 0.854902 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_6032_140187"
          result="effect2_innerShadow_6032_140187"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.083} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.495741 0 0 0 0 0.0395814 0 0 0 0 0.854152 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_6032_140187"
          result="effect3_innerShadow_6032_140187"
        />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height={4}
        id="arm_svg__G"
        width={3.999}
        x={8.998}
        y={3.333}
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={-0.334}
          dy={0.334}
        />
        <feGaussianBlur stdDeviation={0.167} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0.943902 0 0 0 0.3 0" />
        <feBlend
          in2="shape"
          result="effect1_innerShadow_6032_140187"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset
          dx={0.334}
          dy={-0.334}
        />
        <feGaussianBlur stdDeviation={0.167} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.494118 0 0 0 0 0.0392157 0 0 0 0 0.854902 0 0 0 0.25 0" />
        <feBlend
          in2="effect1_innerShadow_6032_140187"
          result="effect2_innerShadow_6032_140187"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.083} />
        <feComposite
          in2="hardAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
        />
        <feColorMatrix values="0 0 0 0 0.495741 0 0 0 0 0.0395814 0 0 0 0 0.854152 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_6032_140187"
          result="effect3_innerShadow_6032_140187"
        />
      </filter>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__b"
        x1={9.133}
        x2={-1.22}
        y1={0.339}
        y2={16.004}
      >
        <stop stopColor="#FCCACB" />
        <stop
          offset={0.207}
          stopColor="#FEC676"
        />
        <stop
          offset={0.581}
          stopColor="#FDB029"
        />
        <stop
          offset={1}
          stopColor="#FF6800"
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__e"
        x1={9.133}
        x2={-1.22}
        y1={0.339}
        y2={16.004}
      >
        <stop stopColor="#FCCACB" />
        <stop
          offset={0.207}
          stopColor="#FEC676"
        />
        <stop
          offset={0.581}
          stopColor="#FDB029"
        />
        <stop
          offset={1}
          stopColor="#FF6800"
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__g"
        x1={6.004}
        x2={3.388}
        y1={7.004}
        y2={5.862}
      >
        <stop stopColor="#F5701F" />
        <stop
          offset={1}
          stopColor="#F5701F"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__k"
        x1={5.334}
        x2={4.472}
        y1={6.004}
        y2={4.896}
      >
        <stop stopColor="#B55012" />
        <stop
          offset={1}
          stopColor="#F5701F"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__m"
        x1={2.335}
        x2={4.593}
        y1={4.67}
        y2={5.285}
      >
        <stop stopColor="#B55012" />
        <stop
          offset={1}
          stopColor="#F5701F"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__o"
        x1={6.64}
        x2={4.366}
        y1={8}
        y2={6.048}
      >
        <stop stopColor="#F5701F" />
        <stop
          offset={1}
          stopColor="#F5701F"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__q"
        x1={13.006}
        x2={12.005}
        y1={16.344}
        y2={11.674}
      >
        <stop stopColor="#F5701F" />
        <stop
          offset={1}
          stopColor="#F5701F"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__s"
        x1={5.335}
        x2={4.473}
        y1={12.007}
        y2={10.899}
      >
        <stop stopColor="#F5701F" />
        <stop
          offset={1}
          stopColor="#F5701F"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__u"
        x1={6.004}
        x2={6.866}
        y1={12.007}
        y2={10.899}
      >
        <stop stopColor="#F5701F" />
        <stop
          offset={1}
          stopColor="#F5701F"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__w"
        x1={8.895}
        x2={8.895}
        y1={10.377}
        y2={19.715}
      >
        <stop
          offset={0.62}
          stopColor="#FFE2B7"
        />
        <stop
          offset={1}
          stopColor="#FFE2B7"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__y"
        x1={2.889}
        x2={2.889}
        y1={8.153}
        y2={21.495}
      >
        <stop
          offset={0.62}
          stopColor="#FFE2B7"
        />
        <stop
          offset={1}
          stopColor="#FFE2B7"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__A"
        x1={2.892}
        x2={2.892}
        y1={10.821}
        y2={24.163}
      >
        <stop
          offset={0.62}
          stopColor="#FFE2B7"
        />
        <stop
          offset={1}
          stopColor="#FFE2B7"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__F"
        x1={14.775}
        x2={12.664}
        y1={0.666}
        y2={4.666}
      >
        <stop
          offset={0.252}
          stopColor="#B3CCF4"
        />
        <stop
          offset={0.77}
          stopColor="#A36EEC"
        />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="arm_svg__H"
        x1={11.925}
        x2={10.165}
        y1={3.667}
        y2={7}
      >
        <stop
          offset={0.252}
          stopColor="#B3CCF4"
        />
        <stop
          offset={0.77}
          stopColor="#A36EEC"
        />
      </linearGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(0 1.16737 -1.89072 0 6.559 2.035)"
        gradientUnits="userSpaceOnUse"
        id="arm_svg__i"
        r={1}
      >
        <stop
          offset={0.746}
          stopColor="#DA8653"
        />
        <stop
          offset={1}
          stopColor="#FA9151"
          stopOpacity={0}
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(2.33474 0 0 3.53913 6.671 2.335)"
        gradientUnits="userSpaceOnUse"
        id="arm_svg__C"
        r={1}
      >
        <stop stopColor="#AA4B39" />
        <stop
          offset={1}
          stopColor="#AA4B39"
          stopOpacity={0}
        />
      </radialGradient>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(2.33474 0 0 3.53913 6.671 2.335)"
        gradientUnits="userSpaceOnUse"
        id="arm_svg__D"
        r={1}
      >
        <stop stopColor="#731604" />
        <stop
          offset={0.713}
          stopColor="#AA4B39"
          stopOpacity={0}
        />
      </radialGradient>
      <clipPath id="arm_svg__a">
        <path
          d="M0 0h16v16H0z"
          fill="#fff"
        />
      </clipPath>
    </defs>
  </svg>
);
