import { styled } from '@/stitches/index.js';
import { type ComponentPropsWithoutRef } from 'react';

const SelectMenuTitle = styled('h3', {
  fontSize: 'inherit',
  margin: 0,
});

const StyledSelectMenuHeader = styled('header', {
  '&:not(:first-child)': {
    borderTopColor: '$gray30',
    borderTopStyle: 'solid',
    borderTopWidth: '$1',
  },
  color: '$gray60',
  fontSize: '$14',
  padding: '$12 $16 $4',
});

const SelectMenuHeader = ({
  children,
  ...props
}: ComponentPropsWithoutRef<typeof StyledSelectMenuHeader>) => (
  <StyledSelectMenuHeader {...props}>
    <SelectMenuTitle>{children}</SelectMenuTitle>
  </StyledSelectMenuHeader>
);

SelectMenuHeader.displayName = 'SelectMenu.Header';

export { SelectMenuHeader };
