import { useCallback, useState } from 'react';

type UseDeterministicToggleReturn = {
  close: () => void;
  isOpen: boolean;
  open: () => void;
};

type UseDeterministicToggle = (options?: {
  isOpenByDefault?: boolean;
}) => UseDeterministicToggleReturn;

export const useDeterministicToggle: UseDeterministicToggle = ({
  isOpenByDefault = false,
} = {}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return { close, isOpen, open };
};
