import {
  Body,
  ButtonBox,
  Buttons,
  CallToAction,
  Close,
  CloseButtonContainer,
  Container,
  Content,
  Description,
  Divider,
  Image,
  Overlay,
  ProgressBar,
  ProgressWrapper,
  Title,
} from './Modal.styles.js';
import { nonFluidTheme } from '@/stitches/nonFluidTheme.js';
import { CloseIcon } from '@contra/icons/icons';
import { Portal, Root, Trigger } from '@radix-ui/react-dialog';
import { type CSS } from '@stitches/react';
import { type ComponentPropsWithoutRef } from 'react';

/**
 * Modal components using Radix UI to handle all the a11y and guts.
 * See: https://www.radix-ui.com/docs/primitives/components/dialog
 */

const ModalCloseButton = ({
  fullImage,
  onClick,
  ...props
}: ComponentPropsWithoutRef<typeof CloseButtonContainer> & {
  readonly fullImage?: boolean;
  readonly onClick?: () => void;
}) => {
  return onClick ? (
    <CloseButtonContainer
      {...props}
      aria-label="Close modal"
      fullImage={fullImage}
      onClick={onClick}
    >
      <CloseIcon />
    </CloseButtonContainer>
  ) : (
    <Close
      aria-label="Close modal"
      fullImage={fullImage}
      {...props}
    >
      <CloseIcon />
    </Close>
  );
};

const ModalContent = ({
  children,
  css,
  fullImage = false,
  progressPercent,
  variant = 'modal',
  ...props
}: ComponentPropsWithoutRef<'div'> & {
  readonly css?: CSS;
  readonly fullImage?: boolean;
  readonly progressPercent?: number;
  readonly variant?: 'drawer' | 'modal' | 'modalSmol' | 'modalNew';
}) => {
  return (
    <Portal>
      <Overlay>
        <Container variant={variant}>
          <Content
            // eslint-disable-next-line react/forbid-component-props
            className={
              variant === 'modal' || variant === 'modalNew'
                ? nonFluidTheme
                : undefined
            }
            css={css}
            fullImage={fullImage}
            variant={variant}
            {...props}
          >
            {typeof progressPercent === 'number' && progressPercent > 0 ? (
              <ProgressWrapper>
                <ProgressBar css={{ width: `${progressPercent}%` }} />
              </ProgressWrapper>
            ) : null}
            {children}
          </Content>
        </Container>
      </Overlay>
    </Portal>
  );
};

const Modal = {
  Body,
  ButtonBox,
  Buttons,
  CallToAction,
  Close: ModalCloseButton,
  Content: ModalContent,
  Description,
  Divider,
  Image,
  Root,
  Title,
  Trigger,
};

export { Modal };
