import {
  ALL_RANGE_INPUT_LABELS,
  FIXED_RATE_RANGE_INPUT_KEYS,
  FIXED_RATE_RANGE_INPUT_LABELS,
  HOURLY_RANGE_INPUT_KEYS,
  RATE_BASED_RANGE_INPUT_LABELS,
} from './constants.js';
import { type FixedRateRange, type RateBasedRange } from './types.js';
import {
  getBudgetRangeKeyByValue,
  getBudgetRangeValuesFromInputType,
  isBudgetRangeValue,
} from './utilities.js';
import { AddVerticalFades } from '@/components/AddVerticalFades/AddVerticalFades.js';
import { SelectMenu } from '@/components/SelectMenu/SelectMenu.js';
import { TextField } from '@/components/TextField/TextField.js';
import { styled } from '@/stitches/index.js';
import { useSelect } from 'downshift';
import { useMemo } from 'react';

type FixedRateRangeInputProps = {
  readonly budgetError?: string | null;
  readonly disabled?: boolean;
  readonly hasError?: boolean;
  readonly initialSelection?: string;
  readonly onBlur?: () => void;
  readonly onSelection: (argument: {
    feeMax?: number;
    feeMin?: number;
    id: string;
    title: string;
  }) => void;
  readonly variant?: 'default' | 'new';
};

const StyledTextField = styled(TextField, {
  '&&& > button > span:first-child': {
    margin: '0 0 $6 $16',
  },
  boxShadow: '$basic',
});

export const BudgetRangeSelect = ({
  disabled = false,
  hasError,
  initialSelection,
  onSelection,
  variant = 'default',
}: FixedRateRangeInputProps) => {
  const initialBudgetRangeKey = useMemo(() => {
    if (!initialSelection || !isBudgetRangeValue(initialSelection)) {
      return undefined;
    }

    return getBudgetRangeKeyByValue(initialSelection);
  }, [initialSelection]);

  const {
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    selectedItem: rateRange,
  } = useSelect<FixedRateRange | RateBasedRange>({
    initialSelectedItem: initialBudgetRangeKey,
    items: [...FIXED_RATE_RANGE_INPUT_KEYS, ...HOURLY_RANGE_INPUT_KEYS],
    onSelectedItemChange: ({ selectedItem }) => {
      if (!disabled && selectedItem) {
        const budgetRange = getBudgetRangeValuesFromInputType(selectedItem);

        onSelection({
          ...budgetRange,
          id: selectedItem,
          title: ALL_RANGE_INPUT_LABELS[selectedItem],
        });
      }
    },
  });

  return (
    <StyledTextField
      hasError={hasError}
      variant={variant}
    >
      <TextField.Label {...getLabelProps()}>
        Estimated Budget Range
      </TextField.Label>
      <TextField.InputGroup
        aria-disabled={disabled}
        as="button"
        type="button"
        variant={variant}
        {...getToggleButtonProps()}
      >
        <TextField.SelectionText
          isDisabled={disabled}
          isPlaceholder={!rateRange}
        >
          {rateRange ? ALL_RANGE_INPUT_LABELS[rateRange] : '-Select-'}
        </TextField.SelectionText>

        {disabled ? (
          <TextField.Disabled />
        ) : (
          <TextField.Chevron isOpen={isOpen} />
        )}
      </TextField.InputGroup>
      {disabled ? null : (
        <TextField.Menu>
          <SelectMenu>
            <SelectMenu.Dialog {...getMenuProps({})}>
              {isOpen && (
                <AddVerticalFades fadeHeight={80}>
                  <SelectMenu.List
                    css={{ maxHeight: '$240', overflowY: 'scroll' }}
                  >
                    <SelectMenu.Header>Total Budget</SelectMenu.Header>
                    {FIXED_RATE_RANGE_INPUT_KEYS.map((item, index) => {
                      return (
                        <SelectMenu.Item
                          isHighlighted={highlightedIndex === index}
                          key={item}
                          {...getItemProps({ index, item })}
                        >
                          {FIXED_RATE_RANGE_INPUT_LABELS[item]}
                        </SelectMenu.Item>
                      );
                    })}
                    <SelectMenu.Header>Hourly Rate</SelectMenu.Header>
                    {HOURLY_RANGE_INPUT_KEYS.map((item, index) => {
                      return (
                        <SelectMenu.Item
                          isHighlighted={highlightedIndex === index}
                          key={item}
                          {...getItemProps({
                            index: index + FIXED_RATE_RANGE_INPUT_KEYS.length,
                            item,
                          })}
                        >
                          {RATE_BASED_RANGE_INPUT_LABELS[item]}
                        </SelectMenu.Item>
                      );
                    })}
                  </SelectMenu.List>
                </AddVerticalFades>
              )}
            </SelectMenu.Dialog>
          </SelectMenu>
        </TextField.Menu>
      )}
    </StyledTextField>
  );
};
